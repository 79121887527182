import './ShareLink.scss';

import classNames from 'classnames';
import AttachedModal from 'components/AttachedModal';
import { ReactComponent as CopyToClipboardIcon } from 'components/Icon/CopyToClipboard.svg';
import { Icon } from 'componentsNew';
import copyToClipboard from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { GAonArticleShared } from 'utils/analytics';

const confirmClipboardShareMessageTimeoutMs = 1500;

const confirmEmailSharingActionTimeoutMs = 7500;

class ShareLink extends Component {
  confirmActionTimeoutHandle = null;
  _isMounted = false;

  state = {
    miniMenuKey: 0,
    selectedOption: null,
  };

  handleShareWithEmailButtonClicked = (e) => {
    const { contentType, articleTitle } = this.props;
    e.stopPropagation();
    if (this.confirmActionTimeoutHandle !== null) {
      return;
    }
    GAonArticleShared(articleTitle);
    let mailtoHref;
    switch (contentType) {
      case 'newsOrBlogsArticle':
        mailtoHref = this.newsOrBlogMailtoHref;
        break;
      case 'insightsArticle':
        mailtoHref = this.insightsMailtoHref;
        break;
      case 'jobAdvert':
        mailtoHref = this.jobsMailtoHref;
        break;
      default:
        throw new Error('Invalid prop value');
    }
    window.open(mailtoHref);
    this.confirmActionTimeoutHandle = setTimeout(
      this.handleConfirmTimeout,
      confirmEmailSharingActionTimeoutMs
    );
    this.setState({ selectedOption: 'email' });
  };

  handleCopyToClipboardClicked = (e) => {
    if (this._isMounted) {
      e.stopPropagation();
      if (
        this.confirmActionTimeoutHandle !== null ||
        !copyToClipboard(window.location.href)
      ) {
        return;
      }
      const { articleTitle } = this.props;
      GAonArticleShared(articleTitle);
      this.confirmActionTimeoutHandle = setTimeout(
        this.handleConfirmTimeout,
        confirmClipboardShareMessageTimeoutMs
      );
      this.setState({ selectedOption: 'clipboard' });
    }
  };

  handleConfirmTimeout = () => {
    if (!this._isMounted) {
      return;
    }
    this.confirmActionTimeoutHandle = null;
    // Increasing the key closes the modal.
    this.setState((state) => ({
      miniMenuKey: state.miniMenuKey + 1,
    }));
  };

  handleModalClosed = () => {
    clearTimeout(this.confirmActionTimeoutHandle);
    this.confirmActionTimeoutHandle = null;
    this.setState((state) => ({
      miniMenuKey: state.miniMenuKey + 1,
    }));
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    if (this.confirmActionTimeoutHandle !== null) {
      clearTimeout(this.confirmActionTimeoutHandle);
    }
    this._isMounted = false;
  }

  get newsOrBlogMailtoHref() {
    const subjectText = 'Check out this article';
    const bodyText = `Hi,\n\nHere is a link to an article in Avenue that I would like to share with you: ${window.location.href}\n`;
    return (
      `mailto:?subject=${encodeURIComponent(subjectText)}` +
      `&body=${encodeURIComponent(bodyText)}`
    );
  }

  get insightsMailtoHref() {
    const subjectText = 'Check out this article';
    const bodyText = `${
      'Hi,\n\nBelow is a link to an article in the ASSA ABLOY Insights Database that ' +
      'I would like to share with you.\n\nThe Insights database is a section of the internal ' +
      'Innovation Portal on Avenue, where colleagues around the group share ' +
      'insights, experiences and knowledge related to Innovation.\n\n'
    }${window.location.href}`;
    return (
      `mailto:?subject=${encodeURIComponent(subjectText)}` +
      `&body=${encodeURIComponent(bodyText)}`
    );
  }

  get jobsMailtoHref() {
    const subjectText = 'Check out this position';
    const bodyText = `${
      'Hi,\n\nBelow is a link to a position that was posted in the ASSA ABLOY Jobs-section that ' +
      'I would like to share with you.\n\n'
    }${window.location.href}`;
    return (
      `mailto:?subject=${encodeURIComponent(subjectText)}` +
      `&body=${encodeURIComponent(bodyText)}`
    );
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <AttachedModal
        className={classNames('share-link', this.props.className)}
        buttonClassName="button button--none button--small button--narrow share-link__button"
        buttonContent={
          <>
            <Icon type="arrowUturnRight" color="brandBase" size={20} />
            <span>Share</span>
          </>
        }
        onClosed={this.handleModalClosed}
        key={this.state.miniMenuKey}
      >
        <div className="share-link__modal-overlay">
          <div className={`share-link__modal-${this.props.modalPlacement}`}>
            <p>Share this article with a colleague</p>
            <button onClick={this.handleCopyToClipboardClicked}>
              Copy link
              <CopyToClipboardIcon />
            </button>
            <div className="share-link__divider">or</div>
            <button onClick={this.handleShareWithEmailButtonClicked}>
              Share with email
            </button>
            <div
              className={classNames('share-link__confirmation-message', {
                'share-link__confirmation-message--visible':
                  this.confirmActionTimeoutHandle !== null,
                'share-link__confirmation-message--email-option':
                  selectedOption === 'email',
                'share-link__confirmation-message--clipboard-option':
                  selectedOption === 'clipboard',
              })}
            />
          </div>
        </div>
      </AttachedModal>
    );
  }
}

ShareLink.propTypes = {
  className: PropTypes.string,
  contentType: PropTypes.oneOf([
    'newsOrBlogsArticle',
    'insightsArticle',
    'jobAdvert',
  ]),
  modalPlacement: PropTypes.oneOf(['top', 'bottom']),
};

ShareLink.defaultProps = {
  contentType: 'newsOrBlogsArticle',
  modalPlacement: 'top',
};

export default ShareLink;
