import {
  differenceInCalendarDays,
  endOfDay,
  format,
  formatDistance,
  isAfter,
  isBefore,
  isToday,
  parseISO,
  startOfDay,
} from 'date-fns';

export const differenceInDays = differenceInCalendarDays;

export const getDaysAgo = (utcDateTime) => {
  const now = new Date();
  const date = parseISO(utcDateTime);

  return differenceInCalendarDays(now, date);
};

export const getDateOrDaysAgo = (utcDateTime, useISOString = true) => {
  const now = new Date();
  const date = parseISO(utcDateTime);

  const difference = differenceInCalendarDays(now, date);

  if (difference > 7 || difference < -7) {
    if (useISOString) {
      return date.toLocaleDateString();
    }
    return format(date, 'yyyy-MM-dd');
  }

  return formatDistance(date, now, {
    addSuffix: true,
  }).replace('about', '');
};

export const getFormatArray = (utcDateTime, dateFormat) => {
  const time = format(parseISO(utcDateTime), dateFormat);

  return time.split(' ');
};

export const getDateFromUTC = (utcDateTime) => {
  return parseISO(utcDateTime);
};

export const dateTimeToUTCDate = (date, time) => {
  const toDate = format(date, 'yyyy-MM-dd');
  const dateString = new Date(`${toDate}T${time}`);
  return dateString.toISOString();
};

export const getTime = (time, timeFormat) => {
  return format(time, timeFormat);
};

export const getStartOfDay = (dateStr) => {
  const result = startOfDay(new Date(dateStr));
  return result;
};

export const getEndOfDay = (dateStr) => {
  const result = endOfDay(new Date(dateStr));
  return result;
};

export const formatDateforGA = (dateStr, formatStr) => {
  const result = format(dateStr, formatStr);
  return result;
};

export const getFormattedDate = (ISOdate) => {
  const date = new Date(ISOdate);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const getNormalFormattedDate = (ISOdate) => {
  const date = new Date(ISOdate);
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

export const getIsToday = (ISOdate) => {
  const date = new Date(ISOdate);
  return isToday(date);
};

export const getIsAM = (ISOdate) => {
  const date = new Date(ISOdate);
  const hours = date.getHours();
  return hours < 12;
};

export const isBeforeNow = (ISOdate) => {
  const now = new Date();
  const date = new Date(ISOdate);
  return isBefore(date, now);
};

export const isAfterNow = (ISOdate) => {
  const now = new Date();
  const date = new Date(ISOdate);
  return isAfter(date, now);
};
