import Stack from '@mui/material/Stack';
import { Icon } from 'componentsNew';

type InfoBoxProps = {
  ariaLabel?: string;
  children: React.ReactNode;
};

const InfoBox = ({ ariaLabel, children }: InfoBoxProps) => {
  return (
    <Stack
      component="section"
      aria-label={ariaLabel}
      sx={(theme) => ({
        backgroundColor: theme.colors.surface.secondary,
        border: `${theme.border.thickness[1]} solid ${theme.colors.border.surfacePrimary}`,
        borderRadius: theme.border.radius.md,
        padding: theme.spacing('md'),
        rowGap: theme.spacing('sm'),
      })}
    >
      <Icon type="informationCircleFilled" color="brandBase" />
      {children}
    </Stack>
  );
};

export { InfoBox };
