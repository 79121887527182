import { HeroBannerSize } from 'api/cms/HeroBanner/types';
import { ShortcutTypeName } from 'api/organization/types';
import settings from 'settings';

import loadGA from './loadGA';

const { analyticsKey } = settings.misc;

const EVENT_CATEGORY = {
  ENGAGEMENT: 'Engagement',
  LIBRARY: 'Library',
  DOCUMENTS: 'Documents',
  FIND: 'Find',
  FIND_INNOVATION_PORTAL: 'Find Innovation Portal',
  FIND_STRATEGY_PORTAL: 'Find Strategy Portal',
  FIND_COC_PORTAL: 'Find Code of Conduct Portal',
  FIND_INSIGHTS_DATABASE: 'Find Insights Database',
  FEEDBACK: 'Feedback',
  ONBOARDING: 'Onboarding',
  JOBS: 'Jobs',
  OUTBOUND: 'Outbound',
  CHANNEL: 'Channel',
  CONTACT: 'Contact',
  ERROR: 'Error',
  FEED: 'Feed',
  HOME: 'Home',
  PRODUCT_OF_THE_WEEK: 'Product of the week',
  PROFILE: 'Profile',
  MANAGE_FEED_CONTENT: 'Manage Feed Content',
  MANAGE_LIBRARY_CONTENT: 'Manage Library Content',
  TRANSLATION: 'Translation',
  TOOLS: 'Tools and projects',
  CUSTOM_PAGE_VIEW: 'Pageview',
  AI: 'AI',
};

const sendEvent = (eventData) => {
  if (!eventData.title) {
    eventData.title = document.title;
  }
  window.gtag('event', eventData.eventAction, eventData);
};

const getDivisionCountry = (division, country) => {
  let divisionName = division;
  let countryName = country;

  if (division && division.name) {
    divisionName = division.name;
  }
  if (country && country.name) {
    countryName = country.name;
  }

  return `${divisionName || 'No specified division'} & ${
    countryName || 'No specified country'
  }`;
};

export const getPortalType = (portalType) => {
  if (portalType === 'innovation') {
    return EVENT_CATEGORY.FIND_INNOVATION_PORTAL;
  } else if (portalType === 'strategy') {
    return EVENT_CATEGORY.FIND_STRATEGY_PORTAL;
  } else {
    return EVENT_CATEGORY.FIND_COC_PORTAL;
  }
};

export const initializeGA = () => {
  if (analyticsKey) {
    loadGA();
  }
};

export const updateGAWithUserData = (
  division,
  department,
  userId,
  regionId,
  isManager,
  countryName
) => {
  window.gtag('set', {
    user_id: userId,
    user_properties: {
      Division: division,
      Function: department,
      Region: regionId,
      Manager: isManager ? '1' : '0',
      Country: countryName,
    },
  });
};

export const GAonPageTitleChange = (pageTitle, locationPath) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.CUSTOM_PAGE_VIEW,
    eventAction: 'Pageview',
    eventLabel: locationPath,
    title: pageTitle,
  });
};

export const GAonRecentSearchClick = (searchPhrase) => {
  if (!searchPhrase) {
    return;
  }

  sendEvent({
    eventCategory: EVENT_CATEGORY.FIND,
    eventAction: 'Recent search click',
    eventLabel: searchPhrase,
  });
};

export const gaOnPublishNewsBlogStory = (articleId) => {
  if (!articleId) {
    return;
  }

  sendEvent({
    eventCategory: EVENT_CATEGORY.FEED,
    eventAction: 'Publish feed',
    eventLabel: articleId,
  });
};

export const GAonClickOnStoryCardItemInFeed = (articleId) => {
  if (!articleId) {
    return;
  }

  sendEvent({
    eventCategory: EVENT_CATEGORY.FEED,
    eventAction: 'Click story card',
    eventLabel: articleId,
  });
};

export const GAonFAQOpen = (parent, question) => {
  sendEvent({
    eventCategory: 'FAQ',
    eventAction: parent,
    eventLabel: question,
  });
};

export const GAonContactClick = (e) => {
  e.preventDefault();
  const dataset = e.target.dataset;

  sendEvent({
    eventCategory: dataset.category,
    eventAction: dataset.action,
    eventLabel: dataset.label,
    hitCallback: function () {
      window.location.href = `${dataset.action}:${dataset.value}`;
    },
  });
};

export const GAonShowMoreClick = (
  eventLabel,
  category = EVENT_CATEGORY.ENGAGEMENT
) => {
  sendEvent({
    eventCategory: category,
    eventAction: 'Show more',
    eventLabel,
  });
};

export const GAonOverlayFilterClick = (eventLabel) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.FIND,
    eventAction: 'Click filter overlay',
    eventLabel,
  });
};

export const GAonSearchFilterClick = (eventLabel) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.FIND,
    eventAction: 'Click filter',
    eventLabel,
  });
};

export const GAonTopicCardClick = (eventLabel) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.ENGAGEMENT,
    eventAction: 'Explore this topic',
    eventLabel,
  });
};

export const GAonFeedContentTypeClick = (eventLabel) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.ENGAGEMENT,
    eventAction: 'Click content type tab',
    eventLabel,
  });
};

export const GAonGlobalFeedClick = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.ENGAGEMENT,
    eventAction: 'Click on Global feed',
    eventLabel: 'Global feed',
  });
};

export const GAonSocialItemClick = (eventLabel) => () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.ENGAGEMENT,
    eventAction: 'Social',
    eventLabel,
  });
};

export const GAonDownloadDocument = (file) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.DOCUMENTS,
    eventAction: 'Document download',
    eventLabel: file.name,
  });
};

export const GAOnPreviewDocument = (file) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.DOCUMENTS,
    eventAction: 'Document preview',
    eventLabel: file.name,
  });
};

export const GAonFeedbackSent = (helpful, title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.FEEDBACK,
    eventAction: helpful ? 'Positive' : 'Negative',
    eventLabel: title,
  });
};

export const GAonCommentFeedClick = (title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.FEEDBACK,
    eventAction: 'Click comment in Feed',
    eventLabel: `Blog / News: ${title}`,
  });
};

export const GAonLikeSent = (title, fromFeed, isCommentLiked) => {
  if (isCommentLiked) {
    sendEvent({
      eventCategory: EVENT_CATEGORY.FEEDBACK,
      eventAction: 'Like a comment',
      eventLabel: `Blog / News: ${title}`,
    });
  } else {
    sendEvent({
      eventCategory: EVENT_CATEGORY.FEEDBACK,
      eventAction: fromFeed ? 'Like in Feed' : 'Like',
      eventLabel: `Blog / News: ${title}`,
    });
  }
};

export const GAonSubmitCommentOrReply = (title, reply = false) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.FEEDBACK,
    eventAction: reply ? 'Reply to comment' : 'Make comment',
    eventLabel: `Blog / News: ${title}`,
  });
};

export const GAonArticleShared = (title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.ENGAGEMENT,
    eventAction: 'Share',
    eventLabel: 'Share',
    pageTitle: title,
  });
};

export const GAonShortcutManageClick = (label) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.TOOLS,
    eventAction: 'Click',
    eventLabel: label,
  });
};

export const GAonShortcutLinkClick = (type, title) => {
  const enumToLabel = {
    tools: 'Tools',
    myProjects: 'Projects',
    [ShortcutTypeName.Tools]: ShortcutTypeName.Tools,
    [ShortcutTypeName.MyProjects]: ShortcutTypeName.MyProjects,
  };

  let typeToLabel;

  if (type in enumToLabel) {
    typeToLabel = enumToLabel[type];
  } else {
    console.warn('Unknown tools or project type');
  }

  sendEvent({
    eventCategory: EVENT_CATEGORY.OUTBOUND,
    eventAction: `Click on ${typeToLabel} link`,
    eventLabel: title,
  });
};

export const GAonShortcutFavoriteClick = (title, checked = true) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.ENGAGEMENT,
    eventAction: `${checked ? 'Add' : 'Remove'} favorite`,
    eventLabel: `Favorite: ${title}`,
  });
};

// AI CHAT

export const GAonAIChatIconClick = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.AI,
    eventAction: 'Click on AI icon',
    eventLabel: 'Open AI',
  });
};

export const GAonAISendPromptClick = (text) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.AI,
    eventAction: 'AI prompt',
    eventLabel: text,
  });
};

export const GAonAISourceListClick = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.AI,
    eventAction: 'Click AI resources list',
    eventLabel: 'Click AI resources list',
  });
};

export const GAonAISourceClick = (title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.AI,
    eventAction: 'Click AI resource',
    eventLabel: title,
  });
};

export const GAonAICopyResultClick = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.AI,
    eventAction: 'Answer interaction',
    eventLabel: 'Copy',
  });
};

export const GAonAIDislikeResultClick = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.AI,
    eventAction: 'Answer interaction',
    eventLabel: 'Negative',
  });
};

export const GAonAIClearClick = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.AI,
    eventAction: 'Clear chat',
    eventLabel: 'Clear',
  });
};

export const GAonAIFeedbackClick = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.AI,
    eventAction: 'Click on feedback',
    eventLabel: 'Click on feedback',
  });
};

// HOME

export const GAonClickHomeFeedFilter = (filterLabel) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'Click home feed filter',
    eventLabel: filterLabel,
  });
};

export const GAonClickHomeFeedItem = (title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'Read more',
    eventLabel: title,
  });
};

export const GAonClickHomeFeedReadMore = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'All news',
    eventLabel: 'All news',
  });
};

export const GAonClickHomeStoriesItem = (title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'Click story card',
    eventLabel: title,
  });
};

export const GAonClickHomeStoriesReadMore = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'All stories',
    eventLabel: 'All stories',
  });
};

export const GAonClickHomeInsightsDatabaseItem = (title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'Click Insights Database article',
    eventLabel: title,
  });
};

export const GAonClickHomeInsightsDatabaseReadMore = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'To Insights Database',
    eventLabel: 'To Insights Database',
  });
};

export const GAonClickHomeUpdatedLibraryPages = (title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'Click updated pages',
    eventLabel: title,
  });
};

export const GAonClickHomeJobsItem = (title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'Click job',
    eventLabel: title,
  });
};

export const GAonClickHomeJobsReadMore = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'All jobs',
    eventLabel: 'All jobs',
  });
};

export const GAonClickHomeSomePost = (body) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'Click LinkedIn post',
    eventLabel: body,
  });
};

export const GAonClickHomeSomeCykelbralla = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'Click LinkedIn profile',
    eventLabel: 'To Nicos profile',
  });
};

export const GAonClickHomeProductOfTheWeekReadMore = (title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction: 'Product of the week',
    eventLabel: title,
  });
};

export const GAonClickHomeBanner = (buttonText, bannerSize) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.HOME,
    eventAction:
      bannerSize === HeroBannerSize.Medium
        ? 'Click hero banner M'
        : 'Click hero banner L',
    eventLabel: buttonText,
  });
};

// PRODUCT OF THE WEEK

export const GAonClickProductOfTheWeekGalleryItem = (title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.PRODUCT_OF_THE_WEEK,
    eventAction: 'Click on item in gallery',
    eventLabel: title,
  });
};

// PROFILE

export const GAonClickProfileTab = (tab) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.PROFILE,
    eventAction: 'Click on profile tab',
    eventLabel: tab,
  });
};

export const GAonClickProfilePreferencesSave = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.PROFILE,
    eventAction: 'Preferences save',
    eventLabel: 'Preferences save',
  });
};

export const GAonClickProfileSubscriptionsSave = (field, value) => {
  if (field === 'subscribesToInsightsDatabase') {
    sendEvent({
      eventCategory: EVENT_CATEGORY.PROFILE,
      eventAction: 'Insights db subscribe',
      eventLabel: value === true ? 'Y' : 'N',
    });
  }
};

// MANAGE_FEED_CONTENT

export const GAonClickManageFeedArticle = (title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.MANAGE_FEED_CONTENT,
    eventAction: 'Click on article in list',
    eventLabel: title,
  });
};

export const GAonChangeManageFeedSort = (label) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.MANAGE_FEED_CONTENT,
    eventAction: 'Change sorting',
    eventLabel: label,
  });
};

export const GAonChangeManageFeedFilter = (label) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.MANAGE_FEED_CONTENT,
    eventAction: 'Change filter',
    eventLabel: label,
  });
};

// MANAGE LIBRARY CONTENT

export const GAonClickManageLibraryPage = (title) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.MANAGE_LIBRARY_CONTENT,
    eventAction: 'Click on page in list',
    eventLabel: title,
  });
};

export const GAonChangeManageLibrarySort = (label) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.MANAGE_LIBRARY_CONTENT,
    eventAction: 'Change sorting',
    eventLabel: label,
  });
};

export const GAonChangeManageLibraryFilter = (label) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.MANAGE_LIBRARY_CONTENT,
    eventAction: 'Change filter',
    eventLabel: label,
  });
};

// INSIGHTS DATABASE

export const GAonInsightsDatabaseSearch = (searchQuery) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.FIND_INSIGHTS_DATABASE,
    eventAction: 'Search Insights Database',
    eventLabel: searchQuery,
  });
};

export const GAonInsightsDatabaseFilter = (filterKey) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.FIND_INSIGHTS_DATABASE,
    eventAction: 'Click Advanced filters',
    eventLabel: filterKey,
  });
};

// LIBRARY
export const GAonLibraryCreatePage = (division, country) => () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.LIBRARY,
    eventAction: 'Create page',
    eventLabel: getDivisionCountry(division, country),
  });
};

export const GAonLibraryEditPage = (division, country) => () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.LIBRARY,
    eventAction: 'Edit page',
    eventLabel: getDivisionCountry(division, country),
  });
};

// SEARCH
export const GAonSearchOpen = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.FIND,
    eventAction: 'Open',
    eventLabel: 'Open Search',
  });
};

export const GAonSearchSubmit = (searchPharse) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.FIND,
    eventAction: 'Searches',
    eventLabel: searchPharse,
  });
};
export const GAonPortalSearchSubmit = (
  searchPharse,
  portalCategory,
  portalType
) => {
  if (!searchPharse && !portalCategory && !portalType) {
    return;
  }

  sendEvent({
    eventCategory: portalCategory,
    eventAction: `Search ${portalType}`,
    eventLabel: searchPharse,
  });
};
export const GAonClickAvenueOverlaySearchResult = (pageTitle) => {
  if (!pageTitle) {
    return;
  }

  sendEvent({
    eventCategory: EVENT_CATEGORY.FIND,
    eventAction: 'Click search result suggestion',
    eventLabel: pageTitle,
  });
};
export const GAonClickPortalSearchResultSuggestion = (
  pageTitle,
  portalType
) => {
  if (!pageTitle && !portalType) {
    return;
  }

  sendEvent({
    eventCategory: portalType,
    eventAction: 'Click search result suggestion',
    eventLabel: pageTitle,
  });
};

export const GAonSearchGetType = (type) => {
  const enumToLabel = {
    article: 'Library',
    blogpost: 'Blog',
    newsarticle: 'News',
    person: 'People',
    pressrelease: 'Press',
    innovationportal: 'Innovation Portal',
    strategyportal: 'Strategy Portal',
    codeofconductportal: 'Code of Conduct Portal',
  };

  let typeToLabel;

  if (type in enumToLabel) {
    typeToLabel = enumToLabel[type];
  } else if (type === null) {
    typeToLabel = 'All';
  } else {
    console.warn('Unknown search type');
  }

  sendEvent({
    eventCategory: EVENT_CATEGORY.FIND,
    eventAction: 'Search category',
    eventLabel: typeToLabel,
  });
};

export const GAonSearchResultClick = (label) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.FIND,
    eventAction: 'Result click',
    eventLabel: label,
  });
};

export const GAonVideoClick = (label) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.CHANNEL,
    eventAction: 'Video click',
    eventLabel: `Video: ${label}`,
  });
};

// ONBOARDING
export const GAonSetOnboardingCarouselIndex = (
  carouselIndex,
  division,
  country
) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.ONBOARDING,
    eventAction: `Go to step ${carouselIndex + 1}`,
    eventLabel: getDivisionCountry(division, country),
  });
};

export const GAonSetOnboardingDontShowAgain = (division, country) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.ONBOARDING,
    eventAction: `Don't show again`,
    eventLabel: getDivisionCountry(division, country),
  });
};

export const GAonOnboardingTakeMeToAvenueClick = (division, country) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.ONBOARDING,
    eventAction: `Take me to Avenue`,
    eventLabel: getDivisionCountry(division, country),
  });
};

// SELECT ITEM FROM LIST
export const GAonFeedReadMoreClick = (eventLabel) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.FEED,
    eventAction: 'Read more', // inconsistent naming compared to recommended jobs
    eventLabel,
  });
};

export const GAonRecommendedJobsListItemClick = (id, name) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.JOBS,
    eventAction: 'Click on Recommended Job',
    eventLabel: `${name} (${id})`,
  });
};

export const GAonSimilarJobsListItemClick = (id, name) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.JOBS,
    eventAction: 'Click on Similar Job',
    eventLabel: `${name} (${id})`,
  });
};

// FILTERS
export const GAonJobsUpdateSelectFilter = (selected) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.JOBS,
    eventAction: 'Add filter',
    eventLabel: selected.map((item) => item.name).join(', '),
  });
};

export const GAonJobsUpdateSeniorityFilter = (names) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.JOBS,
    eventAction: 'Range change',
    eventLabel: JSON.stringify(names),
  });
};

export const GAonJobsResetFilters = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.JOBS,
    eventAction: 'Reset filter',
    eventLabel: 'Reset Jobs filter',
  });
};

// OUTBOUND LINKS
export const GAonExternalMenuItemClick = (label) => () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.OUTBOUND,
    eventAction: 'Click',
    eventLabel: label,
  });
};

export const GAonOutboundButtonClick = (action, label) => {
  sendEvent({
    eventCategory:
      action === 'Found job' ? EVENT_CATEGORY.JOBS : EVENT_CATEGORY.OUTBOUND,
    eventAction: action,
    eventLabel: label,
  });
};

export const GAonFooterSearchIconClick = () => () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.FIND,
    eventAction: 'Search in footer',
  });
};

export const GAonClickBackToOriginal = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.TRANSLATION,
    eventAction: 'Click on header original',
    eventLabel: 'Back to original',
  });
};

export const GAonClickLanguageDropdownMenu = () => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.TRANSLATION,
    eventAction: 'Click on Language menu',
    eventLabel: 'Expand',
  });
};

export const GAonClickLanguageItem = (label) => {
  sendEvent({
    eventCategory: EVENT_CATEGORY.TRANSLATION,
    eventAction: 'Click on Language menu',
    eventLabel: label,
  });
};

export const GAonParsedContentLinkClick =
  ({ type, division, country }, href) =>
  () => {
    if (href.indexOf('mailto:') === 0) {
      sendEvent({
        eventCategory: EVENT_CATEGORY.CONTACT,
        eventAction: 'mailto',
        eventLabel: 'n/a',
      });
    } else if (href.indexOf('tel:') === 0) {
      sendEvent({
        eventCategory: EVENT_CATEGORY.CONTACT,
        eventAction: 'tel',
        eventLabel: 'n/a',
      });
    } else if (href.indexOf('http') === 0) {
      sendEvent({
        eventCategory: `Links ${type}`,
        eventAction: getDivisionCountry(division, country), // This is inconsistent with other events
        eventLabel: href,
      });
    } else {
      console.warn('Unknown link type');
    }
  };

// ERRORS
export const GAreportError = ({ report, statusCode }) => {
  const reportObject = report[statusCode] || report['*'];
  if (reportObject) {
    sendEvent(reportObject);
  }
};

// LIST FAILED TO LOAD
export const GAfailedToLoad = (status, message, type) => {
  if (type === 'job') type = "Can't load jobs feed";
  if (type === 'news') type = "Can't load news feed";
  if (status === 404) {
    sendEvent({
      eventCategory: EVENT_CATEGORY.ERROR,
      eventAction: type,
      eventLabel: `${status}: ${message}`,
    });
  }
};

// SINGLE ITEM NOT FOUND
export const GAitemNotFound = (status, _message, type) => {
  if (type === 'blog') type = 'Blog article not found';
  if (type === 'library') type = 'Library article not found';
  if (type === 'job') type = 'Job not found';
  if (type === 'news') type = 'News article not found';
  if (type === 'person') type = 'Person not found';
  if (status === 404) {
    sendEvent({
      eventCategory: EVENT_CATEGORY.ERROR,
      eventAction: type,
      eventLabel: window.location.href,
    });
  }
};

// POPUPS AND ALERTS
export const GAonSurveyPopupClick = (option) => {
  if (!option) {
    return;
  }
  sendEvent({
    eventCategory: EVENT_CATEGORY.FEEDBACK,
    eventAction: 'Survey',
    eventLabel: option,
  });
};
