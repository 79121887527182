import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactComponent as RobotIcon } from 'componentsNew/Icon/featured/Robot.svg';
import { useMemo } from 'react';

import { AIChatActionCopyResult } from './AIChatActionCopyResult';
import { AIChatActionDislikeResult } from './AIChatActionDislikeResult';
import { AIChatLoadingDots } from './AIChatLoadingDots';
import { ChatItemResult } from './AIChatSheet';
import { AIChatSourceList } from './AIChatSourceList';

type AIChatBubbleResultProps = {
  id: string;
  chatItem?: ChatItemResult;
  loading?: boolean;
  onDisliked?: () => void;
};

const AIChatBubbleResult = ({
  id,
  chatItem,
  loading,
  onDisliked,
}: AIChatBubbleResultProps) => {
  const bubbleContent = useMemo(() => {
    if (loading) {
      return <AIChatLoadingDots />;
    }
    if (!chatItem) {
      return null;
    }
    return (
      <>
        <Typography
          dangerouslySetInnerHTML={{ __html: chatItem.result.text }}
        />
        {chatItem.result.sources && (
          <AIChatSourceList
            id={`${id}-source-list`}
            sources={chatItem.result.sources}
          />
        )}
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            borderRadius: theme.border.radius.md,
            backgroundColor: `${theme.colors.surface.secondary}B3`,
            padding: theme.spacing('xxxxs'),
            gap: theme.spacing('xxxxs'),
          })}
        >
          <AIChatActionCopyResult id={`${id}-copy`} result={chatItem.result} />
          <AIChatActionDislikeResult
            id={`${id}-dislike`}
            chatItem={chatItem}
            onDisliked={onDisliked}
          />
        </Stack>
      </>
    );
  }, [id, loading, chatItem, onDisliked]);

  return (
    <Stack
      id={id}
      sx={(theme) => ({
        flexDirection: 'row',
        gap: theme.spacing('xs'),
        '> svg': { flexShrink: 0 },
      })}
    >
      <RobotIcon height={32} width={32} />
      <Stack
        sx={(theme) => ({
          padding: loading ? theme.spacing('xxs') : theme.spacing('sm'),
          boxShadow: theme.elevation.sm,
          borderRadius: theme.border.radius.lg,
          border: `1px solid ${theme.colors.border.surfaceInformative}`,
          backgroundColor: theme.colors.surface.informative,
          typography: theme.typography.body1,
          wordBreak: 'break-word',
          gap: theme.spacing('xs'),
        })}
      >
        {bubbleContent}
      </Stack>
    </Stack>
  );
};

export { AIChatBubbleResult };
