import classNames from 'classnames';
import { Icon } from 'componentsNew';

const NotificationButton = ({ onClick, newNotification }: any) => (
  <div
    className={classNames('notifications__icon-button', {
      [`notifications__icon-button--new-notification`]:
        Boolean(newNotification),
    })}
    onClick={onClick}
  >
    {newNotification ? (
      <Icon type="bellAlertFilled" color="critical" />
    ) : (
      <Icon type="bell" color="secondary" />
    )}
  </div>
);

export default NotificationButton;
