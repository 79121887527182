import Button from '@mui/material/Button';
import { PromptResult } from 'api/aiSearch/types';
import { Icon, Tooltip } from 'componentsNew';
import { useSnackbar } from 'contextNew/Snackbar';
import copyToClipboard from 'copy-to-clipboard';
import { translations } from 'translations';
import { GAonAICopyResultClick } from 'utils/analytics';

import { parseDomTextContent } from './helpers';

type AIChatActionCopyResultProps = {
  id: string;
  result: PromptResult;
};

const AIChatActionCopyResult = ({
  id,
  result,
}: AIChatActionCopyResultProps) => {
  const { showSnackbar } = useSnackbar();

  return (
    <Tooltip title={translations.copy}>
      <Button
        id={id}
        variant="text"
        aria-label={translations.aiChatCopyResponse}
        sx={(theme) => ({
          height: '2rem',
          width: '2rem',
          padding: '0 !important',
          minWidth: 'unset',
          '&.MuiButton-text:not(.Mui-disabled)': {
            border: `1px solid ${theme.colors.surface.primary}`,
            backgroundColor: theme.colors.surface.primary,
          },
          '&:hover svg': {
            color: theme.colors.icon.brandBase,
          },
        })}
        onClick={() => {
          const parsedContent = parseDomTextContent(result.text);
          copyToClipboard(parsedContent || '');
          showSnackbar({
            text: translations.copyConfirmation,
            autoHideDuration: 3000,
            type: 'success',
          });
          GAonAICopyResultClick();
        }}
      >
        <Icon type="copy" color="secondary" size={16} />
      </Button>
    </Tooltip>
  );
};

export { AIChatActionCopyResult };
