import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon, Tooltip } from 'componentsNew';
import { useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';

type CommentButtonProps = {
  id: string;
  commentsCount: number;
  isCommentedByMe: boolean;
  to?: string;
  href?: string;
  sx?: SxProps<Theme>;
  color?: 'primary' | 'inversePrimary';
  onClick?: () => void;
};

const CommentButton = ({
  id,
  commentsCount,
  isCommentedByMe,
  to,
  href,
  sx,
  color = 'primary',
  onClick,
}: CommentButtonProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const isVariantFilled = isCommentedByMe || isHovering;
  const isVariantOutlined = !isCommentedByMe && commentsCount > 0;
  const isColorPrimary = color === 'primary';

  return (
    <Stack
      id={id}
      sx={[
        (theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing('xxxs'),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Tooltip title={translations.comment}>
        <IconButton
          {...(to ? { to: to, component: ReactRouterLink } : { href: href })}
          onClick={onClick}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          id={`${id}-button`}
          sx={() => ({
            padding: 0,
            '&:hover': { backgroundColor: 'unset' },
          })}
        >
          {isVariantFilled ? (
            <Icon
              type="chatBubbleBottomLeftFilled"
              color={isColorPrimary ? 'brandBase' : 'inverseSecondary'}
            />
          ) : (
            <Icon
              type="chatBubbleBottomLeft"
              color={
                isVariantOutlined && isColorPrimary
                  ? 'brandBase'
                  : isVariantOutlined && color === 'inversePrimary'
                  ? 'inverseSecondary'
                  : 'secondary'
              }
            />
          )}
        </IconButton>
      </Tooltip>
      <Typography
        id={`${id}-count`}
        variant="caption"
        sx={(theme) => ({
          color: isColorPrimary
            ? theme.colors.text.tertiary
            : theme.colors.text.inverseSecondary,
        })}
      >
        {commentsCount}
      </Typography>
    </Stack>
  );
};

export { CommentButton };
