import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { ProductOfTheWeekType } from 'api/cms/ProductOfTheWeek/types';
import notFoundImage from 'assets/images/not-found.jpg';
import { translations } from 'translations';

import { ProductOfTheWeekCard } from './ProductOfTheWeekCard';
import { ProductOfTheWeekGalleryGridSkeleton } from './ProductOfTheWeekGalleryGridSkeleton';

const elementId = 'product-of-the-week-gallery-grid';

export type ProductOfTheWeekGalleryItem = ProductOfTheWeekType & {
  likesMeta?: { likesCount: number; isLikedByMe: boolean };
};

type ProductOfTheWeekGalleryGridProps = {
  isLoading?: boolean;
  items: ProductOfTheWeekGalleryItem[];
  onClick: (item: ProductOfTheWeekGalleryItem) => void;
  onLike: (item: ProductOfTheWeekGalleryItem) => void;
};

const ProductOfTheWeekGalleryGrid = ({
  isLoading,
  items,
  onClick,
  onLike,
}: ProductOfTheWeekGalleryGridProps) => {
  if (isLoading) {
    return <ProductOfTheWeekGalleryGridSkeleton />;
  }

  if (!items.length) {
    return (
      <Stack
        sx={(theme) => ({
          alignItems: 'center',
          padding: theme.spacing('lg'),
        })}
      >
        <Box component="img" src={notFoundImage} maxHeight="300px" />
        <Typography marginTop={1}>{translations.notFound}</Typography>
      </Stack>
    );
  }

  return (
    <Grid
      container
      spacing={1}
      sx={(theme) => ({ padding: theme.spacing('md') })}
    >
      {items.map((item, index) => (
        <Grid key={`${elementId}-item-${index}`} xs={12} sm={6} md={4} lg={3}>
          <ProductOfTheWeekCard
            id={`${elementId}-item-${index}`}
            product={item}
            likesMeta={item.likesMeta}
            onClick={() => onClick(item)}
            onLike={() => onLike(item)}
            sx={{ height: '100%' }}
            view="gallery"
          />
        </Grid>
      ))}
    </Grid>
  );
};

export { ProductOfTheWeekGalleryGrid };
