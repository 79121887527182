import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import PageTitle from 'components/PageTitle';
import { Icon } from 'componentsNew';
import { GlobalStyles } from 'componentsNew/GlobalStyles';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

export const PAGE_GRID_GAP = '1.5rem';
export const PAGE_GRID_PADDING_DESKTOP = '1.5rem';
export const PAGE_GRID_PADDING_MOBILE = '1rem';

type PageProps = {
  title: string | string[];
  breadcrumbs?: { to?: string; text: string }[];
  gridContainer?: boolean;
  children: React.ReactNode;
};

const Page = ({
  title,
  breadcrumbs,
  gridContainer = false,
  children,
}: PageProps) => {
  return (
    <>
      <GlobalStyles />
      {title && <PageTitle titles={Array.isArray(title) ? title : [title]} />}
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          flexGrow: 1,
          background: {
            xs: `linear-gradient(180deg, ${theme.colors.surface.actionPrimaryDefault} 0 405px, ${theme.colors.surface.primary} 405px)`,
            xl: `linear-gradient(180deg, ${theme.colors.surface.actionPrimaryDefault} 0 370px, ${theme.colors.surface.primary} 370px)`,
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            minWidth: 0,
            width: theme.breakpoints.values.xl,
            maxWidth: theme.breakpoints.values.xl,
          })}
        >
          <Grid
            container
            // Spacing between grid items
            spacing={PAGE_GRID_GAP}
            sx={() => ({
              // Spacing around the grid
              padding: {
                xs: PAGE_GRID_PADDING_MOBILE,
                sm: PAGE_GRID_PADDING_DESKTOP,
                xxl: `${PAGE_GRID_PADDING_DESKTOP} 0`,
              },
            })}
          >
            {breadcrumbs && (
              <Grid xs={12} sx={{ marginTop: { xs: '0.5rem', sm: 0 } }}>
                <Breadcrumbs
                  separator={
                    <Icon
                      type="chevronRight"
                      color="inversePrimary"
                      size={20}
                    />
                  }
                  sx={(theme) => ({
                    li: {
                      lineHeight: 1,
                      '> .MuiTypography-root, > .MuiLink-root': {
                        color: theme.colors.text.inversePrimary,
                      },
                      '&:last-of-type': {
                        '> .MuiTypography-root, > .MuiLink-root': {
                          color: theme.colors.text.inverseTertiary,
                        },
                      },
                    },
                  })}
                >
                  {breadcrumbs.map((breadcrumb, i) =>
                    breadcrumb.to ? (
                      <Link
                        key={`page-bread-crumb-${i}`}
                        variant="body2"
                        component={ReactRouterLink}
                        to={breadcrumb.to}
                      >
                        {breadcrumb.text}
                      </Link>
                    ) : (
                      <Typography key={`page-bread-crumb-${i}`} variant="body2">
                        {breadcrumb.text}
                      </Typography>
                    )
                  )}
                </Breadcrumbs>
              </Grid>
            )}
            {gridContainer ? children : <Grid xs={12}>{children}</Grid>}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export { Page };
