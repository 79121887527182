import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon, Tooltip } from 'componentsNew';
import { useState } from 'react';
import { translations } from 'translations';

type LikeButtonProps = {
  id: string;
  likesCount: number;
  isLikedByMe: boolean;
  sx?: SxProps<Theme>;
  color?: 'primary' | 'inversePrimary';
  onClick: () => void;
};

const LikeButton = ({
  id,
  likesCount,
  isLikedByMe,
  sx,
  color = 'primary',
  onClick,
}: LikeButtonProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const isVariantFilled = isLikedByMe || isHovering;
  const isVariantOutlined = !isLikedByMe && likesCount > 0;
  const isColorPrimary = color === 'primary';

  return (
    <Stack
      id={id}
      sx={[
        (theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing('xxxs'),
          flexShrink: 0,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Tooltip title={translations.like}>
        <IconButton
          id={`${id}-button`}
          onClick={onClick}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          sx={() => ({
            padding: 0,
            '&:hover': { backgroundColor: 'unset' },
          })}
        >
          {isVariantFilled ? (
            <Icon
              type="heartFilled"
              color={isColorPrimary ? 'brandBase' : 'inverseSecondary'}
            />
          ) : (
            <Icon
              type="heart"
              color={
                isVariantOutlined && isColorPrimary
                  ? 'brandBase'
                  : isVariantOutlined && color === 'inversePrimary'
                  ? 'inverseSecondary'
                  : 'secondary'
              }
            />
          )}
        </IconButton>
      </Tooltip>
      <Typography
        id={`${id}-count`}
        variant="caption"
        sx={(theme) => ({
          color: isColorPrimary
            ? theme.colors.text.tertiary
            : theme.colors.text.inverseSecondary,
        })}
      >
        {likesCount}
      </Typography>
    </Stack>
  );
};

export { LikeButton };
