import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import MuiTableCell, {
  TableCellProps as MuiTableCellProps,
} from '@mui/material/TableCell';
import { Icon } from 'componentsNew';

type TableCellWithSortProps = {
  sorted: boolean;
  disabled?: boolean;
  onSort: () => void;
  align?: 'right' | 'left';
} & Omit<MuiTableCellProps, 'align'>;

const TableCellWithSort = ({
  sorted,
  disabled,
  onSort,
  align,
  children,
  ...rest
}: TableCellWithSortProps) => {
  return (
    <MuiTableCell {...rest}>
      <Stack
        sx={() => ({
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: align === 'right' ? 'end' : 'space-between',
        })}
      >
        {children}
        <IconButton
          size="small"
          disabled={disabled}
          sx={(theme) => ({
            marginLeft: theme.spacing('xxxs'),
            ':hover': { backgroundColor: 'unset' },
          })}
          onClick={onSort}
        >
          <Icon
            type="chevronUpDown"
            color={sorted && !disabled ? 'brandBase' : 'secondary'}
            size={20}
          />
        </IconButton>
      </Stack>
    </MuiTableCell>
  );
};

export { TableCellWithSort };
