import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import * as feedbackApi from 'api/feedback';
import { useUser } from 'components/Context/User';
import { Icon, Tooltip } from 'componentsNew';
import { useSnackbar } from 'contextNew/Snackbar';
import { useCallback, useState } from 'react';
import { translations } from 'translations';
import { GAonAIDislikeResultClick } from 'utils/analytics';

import { ChatItemResult } from './AIChatSheet';
import { parseDomTextContent } from './helpers';

type AIChatActionCopyResultProps = {
  id: string;
  chatItem: ChatItemResult;
  onDisliked?: () => void;
};

const AIChatActionDislikeResult = ({
  id,
  chatItem,
  onDisliked,
}: AIChatActionCopyResultProps) => {
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useUser();

  const onDislike = useCallback(async () => {
    GAonAIDislikeResultClick();
    setIsLoading(true);
    const parsedContent = parseDomTextContent(chatItem.result.text);
    const answer = parsedContent ? parsedContent : chatItem.result.text;

    try {
      await feedbackApi.sendAIFeedback({
        question: chatItem.prompt,
        answer: answer,
        divisionId: user.divisionId || undefined,
        countryId: user.countryId || undefined,
        siteId: user.siteId || undefined,
        segmentId: user.segmentId || undefined,
        companyId: user.companyId || undefined,
        departmentId: user.departmentId || undefined,
        regionId: user.regionId || undefined,
      });

      if (onDisliked) onDisliked();
      setIsLoading(false);

      showSnackbar({
        text: translations.feedbackConfirmation,
        autoHideDuration: 3000,
        type: 'success',
      });
    } catch (e) {
      setIsLoading(false);

      showSnackbar({
        text: translations.aiChatErrorGeneric,
        autoHideDuration: 3000,
        type: 'error',
      });
    }
  }, [
    chatItem.prompt,
    chatItem.result.text,
    onDisliked,
    showSnackbar,
    user.companyId,
    user.countryId,
    user.departmentId,
    user.divisionId,
    user.regionId,
    user.segmentId,
    user.siteId,
  ]);

  return (
    <Tooltip title={translations.aiChatDislikeResponse}>
      <Box>
        <Button
          id={id}
          variant="text"
          aria-label={translations.aiChatDislikeResponse}
          sx={(theme) => ({
            height: '2rem',
            width: '2rem',
            padding: '0 !important',
            minWidth: 'unset',
            '&.MuiButton-text:not(.Mui-disabled)': {
              border: `1px solid ${theme.colors.surface.primary}`,
              backgroundColor: theme.colors.surface.primary,
            },
            '&:hover': {
              svg: { color: theme.colors.icon.brandBase },
            },
            '&.Mui-disabled': {
              backgroundColor: theme.colors.surface.primary,
              ...((chatItem.isDisliked || isLoading) && {
                svg: {
                  color: theme.colors.icon.brandBase,
                },
              }),
            },
          })}
          disabled={isLoading || chatItem.isDisliked}
          onClick={onDislike}
        >
          <Icon
            size={16}
            color="secondary"
            type={
              chatItem.isDisliked || isLoading ? 'thumbDownFilled' : 'thumbDown'
            }
          />
        </Button>
      </Box>
    </Tooltip>
  );
};

export { AIChatActionDislikeResult };
