import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Badge, Icon } from 'componentsNew';
import {
  FormFieldHelper,
  FormFieldHelperType,
} from 'componentsNew/FormFieldHelper';
import React from 'react';
import settings from 'settings';
import { translations } from 'translations';

import { ImageGalleryItem } from './ImageGalleryEdit';

const { cdnTokenKey } = settings.misc;
const { cms } = settings.cmsBaseImagePath;

const elementId = 'image-list';

type ImageListProps = {
  imageGalleryItems: ImageGalleryItem[];
  onChange: (newImageGalleryItems: ImageGalleryItem[]) => void;
};

export const ImageList = ({ imageGalleryItems, onChange }: ImageListProps) => {
  const onRemove = (index: number) => {
    const newImageGalleryItems = [
      ...imageGalleryItems.slice(0, index),
      ...imageGalleryItems.slice(index + 1),
    ];
    onChange(newImageGalleryItems);
  };

  const getImageSrc = (imageGalleryItem: ImageGalleryItem) => {
    if (imageGalleryItem.isFile) {
      return URL.createObjectURL(imageGalleryItem.image.file);
    }
    return `${cms}${imageGalleryItem.image.path}?${cdnTokenKey}`;
  };

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        border: `1px dashed ${theme.colors.border.surfaceSecondary}`,
        borderRadius: theme.border.radius.md,
        padding: theme.spacing('sm'),
        width: '100%',
        gap: theme.spacing('sm'),
      })}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="body1"
          sx={(theme) => ({
            color: theme.colors.text.tertiary,
            fontWeight: '700',
          })}
        >
          {translations.imageGalleryListHeader}
        </Typography>
        <Badge
          color="primary"
          variant="outlined"
          label={imageGalleryItems.length}
        />
      </Stack>
      {imageGalleryItems.map((imageGalleryItem, index) => (
        <React.Fragment key={`image-list-${index}`}>
          <Stack
            id={`${elementId}-item-${index}`}
            flexDirection="row"
            alignItems="center"
            sx={(theme) => ({
              gap: theme.spacing('xxs'),
            })}
          >
            <Box width="64px" height="64px" flexShrink="0">
              <Box
                component="img"
                src={getImageSrc(imageGalleryItem)}
                sx={() => ({
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                })}
              />
            </Box>
            <Stack
              flexDirection="column"
              width="100%"
              sx={(theme) => ({
                gap: theme.spacing('xxs'),
              })}
            >
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.colors.text.secondary,
                    wordBreak: 'break-word',
                  })}
                >
                  {imageGalleryItem.isFile
                    ? imageGalleryItem.image.file.name
                    : imageGalleryItem.image.name}
                </Typography>
                {Boolean(
                  imageGalleryItem.isFile && imageGalleryItem.image.error
                ) ? (
                  <IconButton
                    id={`${elementId}-item-${index}-remove-button`}
                    onClick={() => onRemove(index)}
                    sx={(theme) => ({
                      '&:hover': {
                        svg: { color: theme.colors.icon.brandBase },
                      },
                      padding: 0,
                    })}
                  >
                    <Icon type="xCircle" color="secondary" size={20} />
                  </IconButton>
                ) : (
                  <Stack
                    sx={(theme) => ({
                      gap: theme.spacing('xxxs'),
                      flexDirection: 'row',
                      alignItems: 'center',
                    })}
                  >
                    <Icon type="checkCircleFilled" color="success" size={20} />
                    <IconButton
                      id={`${elementId}-item-${index}-remove-button`}
                      onClick={() => onRemove(index)}
                      sx={(theme) => ({
                        '&:hover': {
                          svg: { color: theme.colors.icon.critical },
                        },
                        padding: 0,
                      })}
                    >
                      <Icon type="trashFilled" color="secondary" size={20} />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
              {imageGalleryItem.isFile && imageGalleryItem.image.error && (
                <FormFieldHelper
                  type={FormFieldHelperType.Critical}
                  open={Boolean(imageGalleryItem.image.error)}
                >
                  {imageGalleryItem.image.error}
                </FormFieldHelper>
              )}
            </Stack>
          </Stack>
          {index < imageGalleryItems.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Stack>
  );
};
