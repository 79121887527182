import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import logo from 'componentsNew/Icon/featured/AssaAbloyLogo.svg';

const TopHeaderLogo = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="36px"
      width="100%"
      sx={(theme) => ({
        background: theme.colors.surface.secondary,
      })}
    >
      <Stack
        flexDirection="row"
        justifyContent="flex-end"
        sx={(theme) => ({
          width: '100%',
          maxWidth: theme.breakpoints.values.xl,
          minWidth: '0px',
          paddingRight: { md: theme.spacing('md'), xl: '0' },
        })}
      >
        <Box
          component="img"
          src={logo}
          alt="ASSA ABLOY"
          sx={{
            height: '12px',
          }}
        ></Box>
      </Stack>
    </Stack>
  );
};

export { TopHeaderLogo };
