import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import * as organizationApi from 'api/organization';
import { ENTRANCE_SYSTEMS_DIVISION_ID } from 'api/organization/transformers';
import { DivisionWithCountries } from 'api/organization/types';
import { useUser } from 'components/Context/User';
import {
  AlertBar,
  Select,
  SiteSearchSelect,
  TypographyWithLink,
} from 'componentsNew';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { translations } from 'translations';
import { Department, RequestStatus, Segment } from 'types';
import { GAonClickProfilePreferencesSave } from 'utils/analytics';

import { InfoBox } from '../InfoBox';
import {
  PREFERENCES_FORM_FIELD_NAMES,
  PREFERENCES_FORM_FIELD_SETTINGS,
  PreferencesForm,
  PreferencesFormFieldName,
  PreferencesFormValidation,
} from './helpers';
import * as helpers from './helpers';
import { PreferencesFormFieldWrapper } from './PreferencesFormFieldWrapper';
import { PreferencesSkeleton } from './PreferencesSkeleton';
import * as validationHelpers from './validation';

const elementId = 'profile-preferences';

type PreferencesProps = {
  formData: {
    divisionsWithCountries: DivisionWithCountries[];
    departments: Department[];
    segments: Segment[];
  } | null;
};

const Preferences = ({ formData }: PreferencesProps) => {
  const [formInput, setFormInput] = useState<PreferencesForm>(
    helpers.getInitialFormInput()
  );
  const [formValidation, setFormValidation] =
    useState<PreferencesFormValidation>(helpers.getInitialFormValidation());

  const [updateProfileStatus, setUpdateProfileStatus] = useState<RequestStatus>(
    RequestStatus.Idle
  );

  const user = useUser();
  const theme = useTheme();
  const mountedRef = useRef<boolean>(true);

  const isAllowedToChangeDivision = useMemo(
    () => user.roles && user.roles.includes('AvenueCrossDivisionalUser'),
    [user.roles]
  );

  const isSaveButtonVisible = useMemo(
    () =>
      formData &&
      !user.isLoading &&
      updateProfileStatus === RequestStatus.Idle &&
      helpers.didUserMakeAnyChanges(formInput, user) &&
      !PREFERENCES_FORM_FIELD_NAMES.some(
        (fieldName) => !formValidation[fieldName].valid
      ),
    [formData, formInput, formValidation, updateProfileStatus, user]
  );

  const divisionItems = useMemo(() => {
    if (!formData?.divisionsWithCountries.length) {
      return [{ name: user.divisionName, value: user.divisionId }];
    }
    return formData.divisionsWithCountries.map((division) => ({
      name: division.name,
      value: division.id,
    }));
  }, [formData, user.divisionId, user.divisionName]);

  const countryItems = useMemo(() => {
    if (!formData?.divisionsWithCountries.length) {
      return [{ name: user.countryName, value: user.countryId }];
    }
    const validCountries = formData.divisionsWithCountries.find(
      (division) => division.id === formInput.division?.id
    )?.countries;

    if (!validCountries) {
      return [{ name: user.countryName, value: user.countryId }];
    }
    return validCountries.map((country) => ({
      name: country.name,
      value: country.id,
    }));
  }, [formData, formInput.division, user.countryId, user.countryName]);

  const departmentItems = useMemo(() => {
    if (!formData?.departments.length) {
      return [{ name: user.departmentName, value: user.departmentId }];
    }
    return formData.departments.map((department) => ({
      name: department.name,
      value: department.id,
    }));
  }, [formData, user.departmentId, user.departmentName]);

  const segmentItems = useMemo(() => {
    if (!formData?.segments.length) {
      return [{ name: user.segmentName, value: user.segmentValue }];
    }
    return formData.segments.map((segment) => ({
      name: segment.name,
      value: segment.id,
    }));
  }, [formData, user.segmentName, user.segmentValue]);

  const updateProfile = useCallback(async () => {
    setUpdateProfileStatus(RequestStatus.Loading);
    const payload =
      helpers.transformFormInputToUpdateProfileSettingsPayload(formInput);
    try {
      await organizationApi.updateProfileSettings(
        user.userId,
        payload,
        user.subscribesToInsightsDatabase
      );
    } catch {
      if (!mountedRef.current) return;
      setUpdateProfileStatus(RequestStatus.Failure);
      return;
    }
    try {
      await user.fetchUser();
    } catch {
    } finally {
      if (!mountedRef.current) return;
      setUpdateProfileStatus(RequestStatus.Success);
    }
  }, [formInput, user]);

  const updateFieldValidation = useCallback(
    (
      fieldName: PreferencesFormFieldName,
      value: { id: string; name: string } | null
    ) => {
      const validationResult = validationHelpers.validateField(
        value,
        PREFERENCES_FORM_FIELD_SETTINGS[fieldName].validators,
        PREFERENCES_FORM_FIELD_SETTINGS[fieldName].displayName
      );
      setFormValidation({ ...formValidation, [fieldName]: validationResult });
    },
    [formValidation]
  );

  const updateFormValidation = useCallback((formInput: PreferencesForm) => {
    let isFormValid = true;
    const newFormValidation: PreferencesFormValidation =
      helpers.getInitialFormValidation();

    PREFERENCES_FORM_FIELD_NAMES.forEach((fieldName) => {
      const validationResult = validationHelpers.validateField(
        formInput[fieldName],
        PREFERENCES_FORM_FIELD_SETTINGS[fieldName].validators,
        PREFERENCES_FORM_FIELD_SETTINGS[fieldName].displayName
      );
      if (!validationResult.valid) {
        isFormValid = false;
      }
      newFormValidation[fieldName] = validationResult;
    });
    setFormValidation(newFormValidation);
    return isFormValid;
  }, []);

  const onChange = useCallback(
    (
      fieldName: PreferencesFormFieldName,
      value: { id: string; name: string } | null
    ) => {
      if (!formData) return;
      switch (fieldName) {
        case 'division':
          const isCountryStillValid =
            value !== null &&
            formInput.country !== null &&
            helpers.isCountryValidForDivision(
              formInput.country.id,
              value.id,
              formData.divisionsWithCountries
            );
          const isSegmentStillValid =
            value?.id === ENTRANCE_SYSTEMS_DIVISION_ID;
          setFormInput({
            ...formInput,
            division: value,
            ...(!isCountryStillValid && { country: null }),
            ...(!isSegmentStillValid && { segment: null }),
          });
          if (formValidation.division.valid) return;
          updateFieldValidation('division', value);
          break;
        default:
          setFormInput({ ...formInput, [fieldName]: value });
          if (formValidation[fieldName].valid) return;
          updateFieldValidation(fieldName, value);
      }
    },
    [formData, formInput, formValidation, updateFieldValidation]
  );

  const onSubmit = useCallback(() => {
    const isFormValid = updateFormValidation(formInput);
    if (!isFormValid) return;
    GAonClickProfilePreferencesSave();
    updateProfile();
  }, [formInput, updateFormValidation, updateProfile]);

  useEffect(() => {
    if (user.firstLoad) return;
    setFormInput(helpers.getInitialFormInput(user));
  }, [user]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (user.firstLoad || !formData) {
    return <PreferencesSkeleton />;
  }

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing('md'),
        backgroundColor: theme.colors.surface.primary,
      })}
    >
      <Grid
        container
        id={elementId}
        spacing={{ xs: theme.spacing('lg'), md: theme.spacing('sm') }}
      >
        <Grid xs={12} md={7}>
          <Stack sx={(theme) => ({ rowGap: theme.spacing('sm') })}>
            <PreferencesFormFieldWrapper
              id={`${elementId}-division`}
              label={translations.division}
              error={formValidation.division.messages}
              info={
                !formInput.division && !isAllowedToChangeDivision
                  ? [translations.profilePreferencesDivisionInfo]
                  : []
              }
            >
              <Select
                items={divisionItems}
                placeholder={`${translations.select} ${translations.division}`}
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.secondary,
                })}
                disabled={
                  updateProfileStatus === RequestStatus.Loading ||
                  !isAllowedToChangeDivision
                }
                selected={
                  formInput.division && {
                    name: formInput.division.name,
                    value: formInput.division.id,
                  }
                }
                onChange={(item) =>
                  onChange(
                    'division',
                    item ? { id: item.value, name: item.name } : null
                  )
                }
              />
            </PreferencesFormFieldWrapper>

            {formInput.division?.id === ENTRANCE_SYSTEMS_DIVISION_ID && (
              <PreferencesFormFieldWrapper
                id={`${elementId}-segment`}
                label={translations.segment}
                error={formValidation.segment.messages}
              >
                <Select
                  items={segmentItems}
                  placeholder={`${translations.select} ${translations.segment}`}
                  sx={(theme) => ({
                    backgroundColor: theme.colors.surface.secondary,
                  })}
                  disabled={updateProfileStatus === RequestStatus.Loading}
                  selected={
                    formInput.segment && {
                      name: formInput.segment.name,
                      value: formInput.segment.id,
                    }
                  }
                  onChange={(item) =>
                    onChange(
                      'segment',
                      item ? { id: item.value, name: item.name } : null
                    )
                  }
                />
              </PreferencesFormFieldWrapper>
            )}
            <PreferencesFormFieldWrapper
              id={`${elementId}-country`}
              label={translations.country}
              error={formValidation.country.messages}
              info={
                !formInput.division
                  ? [translations.profilePreferencesCountryInfo]
                  : []
              }
            >
              <Select
                items={countryItems}
                placeholder={`${translations.select} ${translations.country}`}
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.secondary,
                })}
                disabled={
                  updateProfileStatus === RequestStatus.Loading ||
                  (!formInput.division && !isAllowedToChangeDivision)
                }
                selected={
                  formInput.country && {
                    name: formInput.country.name,
                    value: formInput.country.id,
                  }
                }
                onChange={(item) =>
                  onChange(
                    'country',
                    item ? { id: item.value, name: item.name } : null
                  )
                }
              />
            </PreferencesFormFieldWrapper>
            <PreferencesFormFieldWrapper
              id={`${elementId}-department`}
              label={translations.department}
              error={formValidation.department.messages}
            >
              <Select
                items={departmentItems}
                placeholder={`${translations.select} ${translations.department}`}
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.secondary,
                })}
                disabled={updateProfileStatus === RequestStatus.Loading}
                selected={
                  formInput.department && {
                    name: formInput.department.name,
                    value: formInput.department.id,
                  }
                }
                onChange={(item) =>
                  onChange(
                    'department',
                    item ? { id: item.value, name: item.name } : null
                  )
                }
              />
            </PreferencesFormFieldWrapper>
            <PreferencesFormFieldWrapper
              id={`${elementId}-site`}
              label={translations.site}
              error={formValidation.site.messages}
            >
              <SiteSearchSelect
                placeholder={`${translations.search} ${translations.site}`}
                disabled={updateProfileStatus === RequestStatus.Loading}
                sx={(theme) => ({
                  '.MuiInputBase-root': {
                    backgroundColor: theme.colors.surface.secondary,
                  },
                })}
                selectedSites={
                  formInput.site
                    ? [
                        {
                          name: formInput.site.name,
                          value: formInput.site.id,
                        },
                      ]
                    : []
                }
                onChange={(items) =>
                  onChange(
                    'site',
                    items.length
                      ? { id: items[0].value, name: items[0].name }
                      : null
                  )
                }
              />
            </PreferencesFormFieldWrapper>
            {isSaveButtonVisible && (
              <Button
                id={`${elementId}-save-button`}
                variant="contained"
                sx={(theme) => ({
                  alignSelf: 'baseline',
                  marginTop: theme.spacing('md'),
                })}
                onClick={onSubmit}
              >
                {translations.save}
              </Button>
            )}
          </Stack>
          <AlertBar
            snackbar
            type="critical"
            open={updateProfileStatus === RequestStatus.Failure}
            text={translations.profileUpdateError}
            onClose={() => setUpdateProfileStatus(RequestStatus.Idle)}
          />
          <AlertBar
            snackbar
            type="success"
            open={updateProfileStatus === RequestStatus.Success}
            text={translations.profileUpdateSuccess}
            onClose={() => setUpdateProfileStatus(RequestStatus.Idle)}
          />
        </Grid>
        <Grid xs={12} md={5}>
          <InfoBox ariaLabel={translations.information}>
            <Typography>{translations.profilePreferencesInfo1}</Typography>
            <Typography>{translations.profilePreferencesInfo2}</Typography>
            <Typography>{translations.profilePreferencesInfo3}</Typography>
            <TypographyWithLink
              href="https://adx.assaabloy.net/SelfService#/MyProperties"
              target="_blank"
            >
              {translations.profilePreferencesInfo4}
            </TypographyWithLink>
          </InfoBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export { Preferences };
