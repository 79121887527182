import Stack from '@mui/material/Stack';
import * as cmsContentManagementApi from 'api/cms/contentManagement';
import { GetContentManagementArticlesResponse } from 'api/cms/contentManagement/types';
import * as feedbackApi from 'api/feedback';
import * as feedbackTransformers from 'api/feedback/transformers';
import { LikesAndCommentsAndViewsCountResponse } from 'api/feedback/types';
import { useUser } from 'components/Context/User';
import { AlertBar, TablePagination } from 'componentsNew';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { translations } from 'translations';
import { RequestStatus } from 'types';

import * as helpers from './helpers';
import {
  ArticleStatus,
  ManageInsightsDatabaseFilter,
} from './ManageInsightsDatabaseFilter';
import {
  ManageInsightsDatabaseTable,
  ManageInsightsDatabaseTableField,
  ManageInsightsDatabaseTableItem,
  SortOrder,
} from './ManageInsightsDatabaseTable';

const elementId = 'profile-manage-insights-database';

const INITIAL_FILTER = {
  articleStatus: ArticleStatus.Published,
};
const INITIAL_SORT = {
  field: ManageInsightsDatabaseTableField.PublishedDate,
  order: SortOrder.Desc,
};
const INITIAL_PAGINATION = {
  page: 1,
  rowsPerPage: 10,
};
const ManageInsightsDatabase = () => {
  const [filter, setFilter] = useState<{
    articleStatus: ArticleStatus;
  }>(INITIAL_FILTER);

  const [sort, setSort] = useState<{
    field: ManageInsightsDatabaseTableField;
    order: SortOrder;
  }>(INITIAL_SORT);

  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>(INITIAL_PAGINATION);

  const [tableContent, setTableContent] = useState<{
    items: ManageInsightsDatabaseTableItem[];
    total: number;
  }>({ items: [], total: 0 });

  const [fetchArticlesStatus, setFetchArticlesStatus] = useState<RequestStatus>(
    RequestStatus.Idle
  );

  const user = useUser();
  const mountedRef = useRef<boolean>(true);

  const fetchArticles = useCallback(
    async (
      filter: { articleStatus: ArticleStatus },
      sort: { field: ManageInsightsDatabaseTableField; order: SortOrder },
      pagination: { page: number; rowsPerPage: number }
    ) => {
      setFetchArticlesStatus(RequestStatus.Loading);
      let items: ManageInsightsDatabaseTableItem[] = [];
      let total = 0;

      try {
        const getArticlesRequest =
          filter.articleStatus === ArticleStatus.Drafts
            ? cmsContentManagementApi.getDraftInsightsDatabaseArticles
            : cmsContentManagementApi.getPublishedInsightsDatabaseArticles;

        const getArticlesQueryParams = helpers.getArticleRequestQueryParams(
          sort,
          pagination
        );

        const response = (await getArticlesRequest(
          getArticlesQueryParams
        )) as GetContentManagementArticlesResponse;

        total = response.data.data.totalRows;
        items = response.data.data.articles.map((cmsArticle) => {
          const item: ManageInsightsDatabaseTableItem = {
            id: cmsArticle.id,
            title: cmsArticle.title,
            type: cmsArticle.type,
            publishedDate: cmsArticle.publishedDate,
            modifiedDate: cmsArticle.modifiedDate,
            informationOwner: cmsArticle.informationOwner,
            linkPath: `/portals/innovation/${cmsArticle.id}`,
          };
          return item;
        });
      } catch {
        if (!mountedRef.current) {
          return { items, total };
        }
        setFetchArticlesStatus(RequestStatus.Failure);
        return { items, total };
      }

      if (filter.articleStatus !== ArticleStatus.Published || !items.length) {
        setFetchArticlesStatus(RequestStatus.Idle);
        return { items, total };
      }

      try {
        const linkPaths = items.map((item) => item.linkPath);
        const response = (await feedbackApi.getLikesCommentsAndViewsCount(
          linkPaths
        )) as LikesAndCommentsAndViewsCountResponse;

        const likesCommentsAndViewsCountByArticleId =
          feedbackTransformers.likesCommentsAndViewsResponseToLikesCommentsAndViewsByArticleId(
            response
          );

        items = items.map((item) => {
          const likesCommentsAndViewsCount =
            likesCommentsAndViewsCountByArticleId[item.id];
          if (!likesCommentsAndViewsCount) {
            return item;
          }
          return {
            ...item,
            likesCount: likesCommentsAndViewsCount.likesCount,
            commentsCount: likesCommentsAndViewsCount.commentsCount,
            newCommentsCount: likesCommentsAndViewsCount.newCommentsCount,
          };
        });
      } catch {
        if (!mountedRef.current) {
          return { items, total };
        }
        setFetchArticlesStatus(RequestStatus.Failure);
        return { items, total };
      }
      setFetchArticlesStatus(RequestStatus.Idle);
      return { items, total };
    },
    []
  );

  const handleFilterChange = useCallback(
    async (newFilter: { articleStatus: ArticleStatus }) => {
      const newSort = {
        field:
          newFilter.articleStatus === ArticleStatus.Drafts
            ? ManageInsightsDatabaseTableField.ModifiedDate
            : ManageInsightsDatabaseTableField.PublishedDate,
        order: SortOrder.Desc,
      };
      const newPagination = {
        ...pagination,
        page: 1,
      };
      setFilter(newFilter);
      setSort(newSort);
      setPagination(newPagination);
      const newTableContent = await fetchArticles(
        newFilter,
        newSort,
        newPagination
      );
      setTableContent(newTableContent);
    },
    [pagination, fetchArticles]
  );

  const handleSortChange = useCallback(
    async (newSort: {
      field: ManageInsightsDatabaseTableField;
      order: SortOrder;
    }) => {
      setSort(newSort);

      if (tableContent.items.length === tableContent.total) {
        const newTableContent = {
          ...tableContent,
          items: helpers.sortTableItems(
            tableContent.items,
            newSort.field,
            newSort.order
          ),
        };
        setTableContent(newTableContent);
        return;
      }
      const newPagination = { ...pagination, page: 1 };
      const newTableContent = await fetchArticles(
        filter,
        newSort,
        newPagination
      );
      setTableContent(newTableContent);
      setPagination(newPagination);
    },
    [tableContent, pagination, fetchArticles, filter]
  );

  const handlePaginationChange = useCallback(
    async (newPagination: { page: number; rowsPerPage: number }) => {
      setPagination(newPagination);
      if (
        pagination.page === 1 &&
        newPagination.page === 1 &&
        newPagination.rowsPerPage < pagination.rowsPerPage
      ) {
        setTableContent({
          ...tableContent,
          items: tableContent.items.slice(0, newPagination.rowsPerPage),
        });
        return;
      }
      const newTableContent = await fetchArticles(filter, sort, newPagination);
      setTableContent(newTableContent);
    },
    [fetchArticles, tableContent, filter, pagination, sort]
  );

  const isLoading = useMemo(
    () => user.firstLoad || fetchArticlesStatus === RequestStatus.Loading,
    [fetchArticlesStatus, user.firstLoad]
  );

  useEffect(() => {
    async function fetchInitialArticles() {
      const newTableContent = await fetchArticles(
        INITIAL_FILTER,
        INITIAL_SORT,
        INITIAL_PAGINATION
      );
      setTableContent(newTableContent);
    }
    fetchInitialArticles();
  }, [fetchArticles]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        backgroundColor: theme.colors.surface.primary,
      })}
    >
      <ManageInsightsDatabaseFilter
        isLoading={isLoading}
        filter={filter}
        onFilterChange={handleFilterChange}
      />
      <ManageInsightsDatabaseTable
        isLoading={isLoading}
        items={tableContent.items}
        filter={filter}
        sort={sort}
        onSortChange={handleSortChange}
        sx={(theme) => ({
          borderTopLeftRadius: theme.border.radius.md,
          borderTopRightRadius: theme.border.radius.md,
        })}
      />
      {tableContent.total > INITIAL_PAGINATION.rowsPerPage && (
        <TablePagination
          disabled={isLoading}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          count={
            tableContent.total % pagination.rowsPerPage > 0
              ? Math.trunc(tableContent.total / pagination.rowsPerPage) + 1
              : tableContent.total / pagination.rowsPerPage
          }
          onPageChange={(value: number) =>
            handlePaginationChange({
              rowsPerPage: pagination.rowsPerPage,
              page: value,
            })
          }
          onRowsPerPageChange={(value: number) =>
            handlePaginationChange({
              rowsPerPage: value,
              page: 1,
            })
          }
        />
      )}
      <AlertBar
        snackbar
        type="critical"
        open={fetchArticlesStatus === RequestStatus.Failure}
        text={translations.manageContentFetchArticlesError}
        onClose={() => {
          if (fetchArticlesStatus !== RequestStatus.Failure) return;
          setFetchArticlesStatus(RequestStatus.Idle);
        }}
      />
    </Stack>
  );
};

export { ManageInsightsDatabase };
