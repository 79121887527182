import './Edit.scss';

import { useTheme } from '@mui/material/styles';
import * as API from 'api/cms/story';
import classNames from 'classnames';
import { AdditionalFeedbackReceivers } from 'components/AdditionalFeedbackReceivers/AdditionalFeedbackReceivers';
import Box from 'components/Box';
import CreateOrEdit, { Consumer } from 'components/CreateOrEditArticle';
import { ArticleInformationOwner } from 'components/CreateOrEditArticle/components/ArticleInformationOwner';
import ArticleMeta from 'components/CreateOrEditArticle/components/ArticleMeta';
import Body from 'components/CreateOrEditArticle/components/Body';
import Breadcrumbs from 'components/CreateOrEditArticle/components/Breadcrumbs';
import Channels from 'components/CreateOrEditArticle/components/Channels';
import ContentLabels from 'components/CreateOrEditArticle/components/ContentLabels';
import DisableComments from 'components/CreateOrEditArticle/components/DisableComments';
import Hero from 'components/CreateOrEditArticle/components/Hero';
import { ImageGallery } from 'components/CreateOrEditArticle/components/ImageGallery';
import Preamble from 'components/CreateOrEditArticle/components/Preamble';
import PriorityField from 'components/CreateOrEditArticle/components/PriorityField';
import PublishOptions from 'components/CreateOrEditArticle/components/PublishOptions';
import PublishSelector from 'components/CreateOrEditArticle/components/PublishSelector';
import ScreenSelector from 'components/CreateOrEditArticle/components/ScreenSelector';
import StoryDisplayOnHome from 'components/CreateOrEditArticle/components/StoryDisplayOnHome';
import SubTitle from 'components/CreateOrEditArticle/components/SubTitle';
import TargetingOptions from 'components/CreateOrEditArticle/components/TargetingOptions';
import Title from 'components/CreateOrEditArticle/components/Title';
import { PublishDateFifteenMinutesStep } from 'components/CreateOrEditArticle/PublishDateFifteenMinutesStep';
import { maxLengthValidator } from 'components/CreateOrEditArticle/validators';
import { Column, Container } from 'components/Grid';
import { History } from 'history';
import { FC, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Division } from 'types';
import { gaOnPublishNewsBlogStory } from 'utils/analytics';
import { getIsPortraitHeroImage } from 'utils/cms';

import ContentLoader from '../ContentLoader';

interface MatchParams {
  id: string;
  type: string;
}

type StoryEditProps = RouteComponentProps<MatchParams>;

const StoryEdit: FC<StoryEditProps> = ({ match, history, location }) => {
  const isCreating = match.params.type === 'create';
  const theme = useTheme();

  const afterStoryCreated = useCallback(
    (
      updatedArticle: any,
      article: any,
      isCreating: boolean,
      history: History
    ) => {
      if (article.isDraft && !updatedArticle.isDraft) {
        gaOnPublishNewsBlogStory(updatedArticle.id);
      } else if (isCreating && !updatedArticle.isDraft) {
        gaOnPublishNewsBlogStory(updatedArticle.id);
      }

      return history.push(`/feed/story/${updatedArticle.id}`);
    },
    []
  );

  const validateDivisions = ({
    publishedInDivisions,
    publishedInGlobal,
  }: {
    publishedInDivisions: Division;
    publishedInGlobal: boolean;
  }) => {
    if (publishedInDivisions || publishedInGlobal) {
      return null;
    }

    return 'Published in division';
  };

  const fields = [
    { key: 'title', required: true, displayName: 'Title', maxLength: 90 },
    {
      key: 'subTitle',
      required: false,
      displayName: 'Subtitle',
      maxLength: 90,
    },
    {
      key: 'preamble',
      fieldValidator: maxLengthValidator(300),
      maxLength: 300,
    },
    { key: 'body' },
    { key: 'imageGalleryItems' },
    { key: 'sharepointFolderName', defaultValue: null },
    { key: 'publishedDate', defaultValue: new Date().toISOString() },
    {
      key: 'publishedInGlobal',
      intermediate: true,
      defaultValue: false,
      getIntermediateDefault: ({
        publishedInDivisions,
      }: {
        publishedInDivisions: Division;
      }) => !publishedInDivisions,
    },
    {
      key: 'publishedInDivisions',
      validator: validateDivisions,
    },
    { key: 'heroAltText' },
    { key: 'heroImage', displayName: 'Hero image', type: 'story' },
    { key: 'heroImageWidth' },
    { key: 'heroImageHeight' },
    { key: 'heroVideo', displayName: 'Hero Video', type: 'story' },
    { key: 'channels', required: true, displayName: 'Topics' },
    { key: 'disableComments', defaultValue: false },
    { key: 'isPriority' },
    { key: 'isDraft', defaultValue: false },
    { key: 'screenEnabled', defaultValue: false },
    { key: 'screenSummary' },
    { key: 'publishedInSegments' },
    { key: 'publishedInCountries' },
    { key: 'publishedInDepartments' },
    { key: 'storyDisplayOnHome', defaultValue: false },
    { key: 'storyDisplayOnHomeDate' },
    { key: 'informationOwner' },
    { key: 'additionalFeedbackReceivers' },
  ];

  return (
    <CreateOrEdit
      isCreating={isCreating}
      viewableByRole="AvenueSuperuser"
      pageTitle="Story"
      location={location}
      fields={fields}
      ContentLoader={ContentLoader}
      onFetch={() => API.getStory(match.params.id)}
      onDelete={API.deleteStory}
      onCreate={API.createStory}
      onEdit={API.updateStory}
      onAfterCreate={(updatedArticle: any, article: any) =>
        afterStoryCreated(updatedArticle, article, isCreating, history)
      }
      onAfterDelete={() => history.push(`/feed`)}
      onAfterCancel={(story: any) =>
        isCreating
          ? history.push(`/feed`)
          : history.push(`/feed/story/${story._doc}`)
      }
      deleteLoadingText="Unpublishing article..."
      deleteSuccessText="Article has been unpublished"
      deleteFailureText="Could not unpublish article"
    >
      <Breadcrumbs subject="story" />
      <Container className="ie-flex-fix">
        <Column>
          <Box className="m-t-6 lg-m-t-0">
            <Consumer>
              {({ updatedArticle }) => {
                const articleEditorClassNames = classNames(
                  'article-editor__content-container',
                  {
                    'article-editor__content-container--portrait-mode':
                      getIsPortraitHeroImage(updatedArticle),
                  }
                );
                return (
                  <div className={articleEditorClassNames}>
                    <div className="article-editor__hero-content">
                      <Hero useVideo={true} useAltText={true} />
                      <span className="article-editor__image-size">
                        Image size: 1050px x 590px
                      </span>
                      <Title placeholder="Story title (max 90 characters)" />
                      <SubTitle placeholder="Story subtitle (max 90 characters)" />
                      <ContentLabels />
                    </div>
                    <div className="max-width article-editor__text-content">
                      <Preamble placeholder="Story preamble (max 300 characters)" />
                      <ImageGallery />
                      <Body placeholder="Story body" />
                    </div>
                  </div>
                );
              }}
            </Consumer>
            <ArticleMeta isBlog={false} />
          </Box>
        </Column>
      </Container>

      <Container>
        <Column>
          <TargetingOptions>
            <PublishSelector
              publishWithSegments={true}
              publishWithSite={false}
              publishWithCompany={false}
              publishWithDepartment={false}
            />
          </TargetingOptions>
        </Column>
      </Container>

      <Container>
        <Column>
          <Consumer>
            {({ updatedArticle }) => {
              const isDraft = updatedArticle.isDraft;
              return (
                <PublishOptions
                  onCancel="/feed"
                  subject="story"
                  showDraftButton={isCreating || isDraft}
                  deleteButtonText="Unpublish"
                >
                  <Channels isCreating={isCreating} />
                  <hr className="m-t-6 m-b-6" />
                  <ScreenSelector />
                  <hr className="m-t-6 m-b-6" />
                  <DisableComments />
                  <hr className="m-t-6 m-b-6" />
                  <PriorityField />
                  <hr className="m-t-6 m-b-6" />
                  <StoryDisplayOnHome />
                  <hr className="m-t-6 m-b-6" />
                  <ArticleInformationOwner />
                  <hr className="m-t-6 m-b-6" />
                  <AdditionalFeedbackReceivers />
                  <hr className="m-t-6 m-b-6" />
                  <PublishDateFifteenMinutesStep
                    sx={{ marginBottom: theme.spacing('sm') }}
                    disabled={!isCreating && !isDraft}
                  />
                </PublishOptions>
              );
            }}
          </Consumer>
        </Column>
      </Container>
    </CreateOrEdit>
  );
};

export default StoryEdit;
