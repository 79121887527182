import { AxiosResponse } from 'axios';
import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { cms } = settings.api;

export const createProductOfTheWeek = (data: object): Promise<AxiosResponse> =>
  createRequest({
    method: 'post',
    url: `${cms}/productoftheweek`,
    data,
    errorMessages: {
      '*': 'Failed to create product of the week article, please contact support for assistance.',
    },
  });

export const editProductOfTheWeek = (
  id: string,
  data: object
): Promise<AxiosResponse> =>
  createRequest({
    method: 'put',
    url: `${cms}/productoftheweek/${id}`,
    data,
    errorMessages: {
      '*': 'Failed to update product of the week article, please contact support for assistance.',
    },
  });

export const getProductOfTheWeek = (): Promise<AxiosResponse> =>
  createRequest({
    method: 'get',
    url: `${cms}/productoftheweek`,
    errorMessages: {
      '*': 'Failed to get product of the week article, please contact support for assistance.',
    },
  });

export const getAllProductOfTheWeek = (
  queryParams: string
): Promise<AxiosResponse> =>
  createRequest({
    method: 'get',
    url: `${cms}/productoftheweek/allProducts${queryParams}`,
    errorMessages: {
      '*': 'Failed to get product of the week articles, please contact support for assistance.',
    },
  });

export const getAllProductOfTheWeekForAdmin = (
  queryParams: string
): Promise<AxiosResponse> =>
  createRequest({
    method: 'get',
    url: `${cms}/productoftheweek/allProducts/admin${queryParams}`,
    errorMessages: {
      '*': 'Failed to get product of the week articles for admin, please contact support for assistance.',
    },
  });

export const getProductOfTheWeekWithId = (id: string): Promise<AxiosResponse> =>
  createRequest({
    method: 'get',
    url: `${cms}/productoftheweek/${id}`,
    errorMessages: {
      '*': 'Failed to get product of the week article, please contact support for assistance.',
    },
  });

export const deleteProductOfTheWeek = (id: string): Promise<AxiosResponse> =>
  createRequest({
    method: 'delete',
    url: `${cms}/productoftheweek/${id}`,
    errorMessages: {
      '*': 'Failed to delete product of the week article, please contact support for assistance.',
    },
  });
