import settings from 'settings';

// inspired by https://github.com/react-ga/react-ga/blob/master/src/utils/loadGA.js
// used instead of putting in a script tag as typically suggested by GA
const loadGA = () => {
  /* eslint-disable */
  // https://developers.google.com/analytics/devguides/collection/ga4
  const { ga4MeasurementId } = settings.misc;
  const ga4Address = `https://www.googletagmanager.com/gtag/js?id=${ga4MeasurementId}`;

  (function(window, document, content, ga4Add, _ga4MeasurementId, a, m) {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    window.gtag = window.gtag || gtag;
    gtag('js', new Date());
    gtag('config', _ga4MeasurementId, { anonymize_ip: true });
    a = document.createElement(content);
    m = document.getElementsByTagName(content)[0];
    a.async = 1;
    a.src = ga4Add;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', ga4Address, ga4MeasurementId);
  /* eslint-enable */
};

export default loadGA;
