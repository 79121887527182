import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import * as organizationApi from 'api/organization';
import * as organizationTranformers from 'api/organization/transformers';
import { Profile } from 'api/organization/types';
import { Icon } from 'componentsNew';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';

import { ProfileMiniSkeleton } from './ProfileMiniSkeleton';

interface ProfileMiniProps {
  id?: string;
  profileId: string;
  profileName?: string;
  hideTitle?: boolean;
  sx?: SxProps<Theme>;
}

const ProfileMini = ({
  id = 'profile-mini',
  profileId,
  profileName,
  hideTitle,
  sx,
}: ProfileMiniProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile | null>(null);

  const mountedRef = useRef<boolean>(true);

  const fetchProfile = useCallback(async (id: string) => {
    try {
      setIsLoading(true);
      const response = await organizationApi.getProfile(id);
      const transformed =
        organizationTranformers.profileResponseToProfile(response);

      if (!mountedRef.current) return;
      setProfile(transformed);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProfile(profileId);
  }, [profileId, fetchProfile]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (isLoading) {
    return <ProfileMiniSkeleton />;
  }

  const fullName =
    `${profile?.firstName || ''} ${profile?.lastName || ''}`.trim() ||
    profileName;

  return (
    <Link
      id={id}
      to={`/people/${profileId}`}
      component={ReactRouterLink}
      sx={(theme) => ({
        textDecoration: 'none',
        '&:hover #profile-mini-name': {
          color: theme.colors.text.actionHover,
        },
      })}
    >
      <Stack direction="row" alignItems="center" sx={sx}>
        {profile?.imageUrl ? (
          <Avatar
            id={`${id}-avatar`}
            alt={fullName || ''}
            src={profile.imageUrl}
            sx={{ height: '2.5rem', width: '2.5rem' }}
          />
        ) : (
          <Icon
            type="userCircle"
            color="secondary"
            svgProps={{ height: 40, width: 40 }}
          />
        )}
        <Stack sx={(theme) => ({ marginLeft: theme.spacing('xs') })}>
          <Typography
            id={`${id}-name`}
            variant="body2"
            sx={(theme) => ({
              color: theme.colors.text.primary,
              lineHeight: 1.5,
              fontWeight: 'bold',
              textDecoration: 'underline',
            })}
          >
            {fullName || translations.notAvailable}
          </Typography>
          {!hideTitle && profile?.title && (
            <Typography
              id={`${id}-title`}
              variant="body2"
              sx={(theme) => ({
                color: theme.colors.text.tertiary,
              })}
            >
              {profile.title}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Link>
  );
};

export { ProfileMini };
