import Box from '@mui/material/Box';
import OldLanguageBanner from 'components/LanguageBanner';
import OldLanguageSelector from 'components/LanguageSelector';
import React, { useState } from 'react';
import { translations } from 'translations';

// OBS: Using old component for LanguageSelector and
// LanguageBanner since we have not decided whether to keep it or not

type LanguageSelectorProps = {
  locale: string;
  setLocale: (locale: string) => void;
  topRightPosition?: boolean;
};

const LanguageSelector = ({
  locale,
  setLocale,
  topRightPosition,
}: LanguageSelectorProps) => {
  const [bannerDismissed, setBannerDismissed] = useState<boolean>(false);

  if (locale !== 'en' && !bannerDismissed) {
    return (
      <LanguageSelectorWrapper topRightPosition={topRightPosition}>
        <Box
          sx={(theme) => ({
            width: '100%',
            '.language-banner': {
              margin: 0,
              backgroundColor: theme.colors.surface.secondary,
              borderRadius: theme.border.radius.sm,
              border: `${theme.border.thickness[1]} solid ${theme.colors.border.surfaceSecondary}`,
            },
            '.language-banner__wrapper': {
              width: '100%',
              paddingTop: '0.5rem !important',
              paddingBottom: '0.5rem !important',
              paddingLeft: '1rem !important',
              paddingRight: '1rem !important',
              svg: {
                top: '0.85rem',
                right: '0.85rem',
                height: '12px',
                width: '12px',
              },
            },
            '.language-banner__description': {
              fontSize: theme.typography.body2.fontSize,
            },
          })}
        >
          <OldLanguageBanner
            id="home-language-banner"
            currentLocale={locale}
            onClose={() => setBannerDismissed(true)}
            onLanguageReset={() => setLocale('en')}
          />
        </Box>
      </LanguageSelectorWrapper>
    );
  }
  return (
    <LanguageSelectorWrapper topRightPosition={topRightPosition}>
      <Box
        sx={(theme) => ({
          display: 'inline-block',
          button: {
            ...theme.typography.button3,
            fontWeight: 'normal',
            borderWidth: theme.border.thickness[1],
            borderRadius: theme.border.radius.sm,
            borderColor: theme.colors.border.surfacePrimary,
            color: theme.colors.text.primary,
            backgroundColor: theme.colors.surface.primary,
            boxShadow: theme.elevation.sm,
            height: '2rem',
            paddingLeft: '0.8rem',
            paddingRight: '2.2rem',
          },
          'button:hover': {
            opacity: 1,
          },
          '> div > div': {
            padding: 0,
          },
          '.language-select__icon-wrapper': {
            padding: '0',
            top: '0.4rem',
            right: '0.5rem',
            svg: {
              height: 20,
              width: 20,
              color: theme.colors.text.secondary,
            },
          },
          '.language-select__options': {
            right: 4,
            boxShadow: theme.elevation.sm,
            borderRadius: theme.border.radius.sm,
          },
        })}
      >
        <OldLanguageSelector
          id="home-language-selector"
          label={translations.translate}
          currentLocale={locale}
          onLanguageChange={(language) => {
            setLocale(language.locale);
            return true;
          }}
        />
      </Box>
    </LanguageSelectorWrapper>
  );
};

type LanguageSelectorWrapperProps = {
  topRightPosition?: boolean;
  children: React.ReactNode;
};

const LanguageSelectorWrapper = ({
  topRightPosition,
  children,
}: LanguageSelectorWrapperProps) => {
  return (
    <Box
      sx={() => ({
        display: 'flex',
        justifyContent: 'flex-end',
        ...(topRightPosition
          ? {
              position: 'absolute',
              top: '1rem',
              right: '1rem',
              zIndex: 2,
              width: 'calc(100% - 2rem)',
            }
          : {
              marginBottom: '1rem',
            }),
      })}
    >
      {children}
    </Box>
  );
};

export { LanguageSelector };
