import Link from '@mui/material/Link';
import MuiSnackbar, {
  SnackbarProps as MuiSnackbarProps,
} from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'componentsNew';
import { useMemo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

export type SnackbarProps = {
  text: string;
  type?: 'success' | 'warning' | 'error';
  action?: {
    text: string;
    to?: string;
    href?: string;
    target?: string;
    onClick?: () => void;
  };
} & Omit<MuiSnackbarProps, 'message' | 'action'>;

const Snackbar = ({
  text,
  type,
  action,
  ...muiSnackbarProps
}: SnackbarProps) => {
  const textAndIconElement = useMemo(() => {
    const textElement = (
      <Typography
        variant="body2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          wordBreak: 'break-word',
        }}
      >
        {text}
      </Typography>
    );
    if (!type) {
      return textElement;
    }

    let iconElement = null;

    switch (type) {
      case 'success':
        iconElement = (
          <Icon type="checkCircleFilled" color="inversePrimary" size={20} />
        );
        break;
      case 'warning':
        iconElement = (
          <Icon
            type="exclamationTriangleFilled"
            color="inversePrimary"
            size={20}
          />
        );
        break;
      case 'error':
        iconElement = (
          <Icon type="xCircleFilled" color="inversePrimary" size={20} />
        );
        break;
    }
    return (
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          gap: theme.spacing('xs'),
        })}
      >
        {iconElement}
        {textElement}
      </Stack>
    );
  }, [text, type]);

  const actionElement = useMemo(() => {
    if (!action) {
      return null;
    }
    return (
      <Link
        {...(action.to
          ? { to: action.to, component: ReactRouterLink }
          : { href: action.href })}
        target={action.target}
        variant="body2"
        onClick={action.onClick}
        sx={(theme) => ({
          color: theme.colors.text.inversePrimary,
          textDecorationColor: theme.colors.text.inversePrimary,
          fontWeight: 700,
          cursor: 'pointer',
        })}
      >
        {action.text}
      </Link>
    );
  }, [action]);

  return (
    <MuiSnackbar
      {...muiSnackbarProps}
      message={textAndIconElement}
      action={actionElement}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={[
        (theme) => ({
          '.MuiPaper-root': {
            color: theme.colors.text.inversePrimary,
            backgroundColor: theme.colors.surface.inversePrimary,
            padding: `${theme.spacing('xs')} ${theme.spacing('sm')}`,
            borderRadius: theme.border.radius.md,
            boxShadow: theme.elevation.md,
            fontSize: theme.typography.body2.fontSize,
          },
          '.MuiSnackbarContent-message': {
            padding: `${theme.spacing('xxxxs')} 0`,
          },
          '.MuiSnackbarContent-action': {
            margin: 0,
            marginLeft: 'auto',
          },
        }),
        ...(Array.isArray(muiSnackbarProps.sx)
          ? muiSnackbarProps.sx
          : [muiSnackbarProps.sx]),
      ]}
    />
  );
};
export { Snackbar };
