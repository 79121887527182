import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Icon } from 'componentsNew';
import { KeyboardKey } from 'constants/keyboard';
import { translations } from 'translations';

const elementId = 'ai-chat-input';

type AIChatInputProps = {
  loading?: boolean;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
};

const AIChatInput = ({
  loading,
  disabled,
  value,
  onChange,
  onSubmit,
}: AIChatInputProps) => {
  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        boxShadow: theme.elevation.sm,
      })}
    >
      <OutlinedInput
        id={elementId}
        size="small"
        multiline
        fullWidth
        minRows={2}
        value={value}
        disabled={disabled}
        autoFocus
        placeholder={translations.aiChatInputPlaceholder}
        inputProps={{ maxLength: 500 }}
        onChange={(e) => onChange(e.target.value)}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          )
        }
        onKeyDown={(e) => {
          if (e.key === KeyboardKey.Enter) {
            e.preventDefault();
            onSubmit();
          }
        }}
        sx={() => ({
          paddingRight: '3.5rem',
        })}
      />
      <Button
        id={`${elementId}-submit`}
        size="medium"
        variant="outlined"
        disabled={disabled || loading}
        onClick={onSubmit}
        startIcon={<Icon type="arrowUp" color="brandBase" />}
        sx={() => ({
          position: 'absolute',
          bottom: '1rem',
          right: '1rem',
          height: '2rem',
          width: '2rem',
          padding: '0 !important',
          minWidth: 'unset',
          '> .MuiButton-startIcon': {
            marginRight: 0,
            marginLeft: 0,
          },
        })}
      />
    </Box>
  );
};

export { AIChatInput };
