import 'styles/global.scss';

import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AppInsights from 'components/AppInsights';
import { AIChatProvider } from 'components/Context/AIChat';
import AlertProvider from 'components/Context/Alert';
import FeedProvider from 'components/Context/Feed';
import { GlobalWebcastProvider } from 'components/Context/GlobalWebcast';
import HeroBannerProvider from 'components/Context/HeroBanner';
import { HomeBannerProvider } from 'components/Context/HomeBanner';
import JobsProvider from 'components/Context/Jobs';
import { MyMomentProvider } from 'components/Context/MyMoment';
import NextArticleProvider from 'components/Context/NextArticle';
import { RedirectsProvider, useRedirects } from 'components/Context/Redirects';
import UserProvider, { UserConsumer, useUser } from 'components/Context/User';
import WhitelistedUrlsProvider from 'components/Context/WhitelistedUrls';
import Footer from 'components/Footer';
import HistoryListener from 'components/HistoryListener';
import Modals from 'components/Modals';
import MenuContent from 'components/Navigation/MenuContent';
import NoUser from 'components/nouser';
import Outline from 'components/Outline';
import PageHeader from 'components/PageHeader';
import Hotjar from 'components/Plugins/Hotjar';
import UserMetaGA from 'components/Plugins/UserMetaGA';
import RedirectAll from 'components/RedirectAll';
import ScrollToTop from 'components/ScrollToTop';
import SurveyPopup from 'components/SurveyPopup';
import { AvenueRoute } from 'constants/routes';
import { AlertsProvider } from 'contextNew/Alerts';
import { SnackbarProvider } from 'contextNew/Snackbar';
import Administration from 'pages/Admin';
import AlertEdit from 'pages/Alert/Edit';
import Alert from 'pages/Alert/View';
import BlogEdit from 'pages/Blog/Edit';
import Blog from 'pages/Blog/View';
import CrossWordCampaign from 'pages/Campaigns/Crossword';
import Feed from 'pages/Feed';
import Find from 'pages/Find';
import HeroBannerEdit from 'pages/HeroBanner/Edit';
import Job from 'pages/Jobs/Article';
import Jobs from 'pages/Jobs/List';
import Library from 'pages/Library';
import LibraryIdRedirect from 'pages/Library/IdRedirect';
import NewsEdit from 'pages/News/Edit';
import NewsArticle from 'pages/News/View';
import Portals from 'pages/Portals';
import PortalsRedirect from 'pages/Portals/Redirect';
import PressArticle from 'pages/Press';
import ProfileOverview from 'pages/Profile';
import NotFound from 'pages/Public/NotFound';
import SomeEdit from 'pages/Some/Edit';
import StoryEdit from 'pages/Story/Edit';
import Story from 'pages/Story/View';
import ChannelEdit from 'pages/Topics/Edit';
import Channels from 'pages/Topics/View';
import {
  CreateOrEditMyMoment,
  CreateOrEditProductOfTheWeek,
  Home,
  MyMoment,
  NewsletterBannerCreate,
  NewsletterBannerEdit,
  ProductOfTheWeekGallery,
  Profile,
} from 'pagesNew';
import { useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import settings from 'settings';
import { createTheme } from 'theme';
import { FeatureToggle, featureToggle } from 'utils/misc/featureToggle';

const { insightsDbPageId } = settings.misc;

const theme = createTheme();

const ForceRedirect = ({ url }) => {
  window.location.href = window.location.origin + url;
  return <div style={{ minHeight: '100vh' }} />;
};

const Avenue = () => {
  const redirects = useRedirects();
  const user = useUser();

  return (
    <GlobalWebcastProvider>
      <HomeBannerProvider>
        <MyMomentProvider>
          <PageHeader signedIn={true} showBlueBackground={true}>
            <UserConsumer>
              {({ navigationKey, ...userContext }) => (
                <MenuContent
                  userContext={userContext}
                  key={`navigation${navigationKey}`}
                />
              )}
            </UserConsumer>
          </PageHeader>
          <main className="main-content">
            <Switch>
              <Route exact path="/" component={Home} />
              <Redirect
                exact
                path={AvenueRoute.Profile}
                to={AvenueRoute.ProfilePreferences}
              />
              <Route
                exact
                path={AvenueRoute.ProfilePreferences}
                component={Profile}
              />
              <Route
                exact
                path={AvenueRoute.ProfileSubscriptions}
                component={Profile}
              />
              <Route
                exact
                path={AvenueRoute.ProfileManageFeed}
                component={Profile}
              />
              <Route
                exact
                path={AvenueRoute.ProfileManageLibrary}
                component={Profile}
              />
              <Route
                exact
                path={AvenueRoute.ProfileManageInsightsDatabase}
                component={Profile}
              />
              {(user.isAdmin || user.isSuperUser) && (
                <Route
                  exact
                  path={AvenueRoute.ProfileManageBanners}
                  component={Profile}
                />
              )}
              <Route exact path="/404" component={NotFound} />
              <Route exact path="/nouser" component={NoUser} />
              <Route exact path="/find" component={Find} />
              <Route path="/jobs">
                <JobsProvider>
                  <Route exact path="/jobs" component={Jobs} />
                  <Route exact path="/jobs/:id" component={Job} />
                </JobsProvider>
              </Route>
              <Route path="/feed">
                <UserConsumer>
                  {({
                    divisionId,
                    divisionName,
                    segmentId,
                    segmentName,
                    countryId,
                    countryName,
                    departmentId,
                    departmentName,
                    siteId,
                    siteName,
                    companyId,
                    companyName,
                    isLoading,
                  }) =>
                    isLoading ? null : (
                      <FeedProvider
                        divisionId={divisionId}
                        divisionName={divisionName}
                        segmentId={segmentId}
                        segmentName={segmentName}
                        countryId={countryId}
                        countryName={countryName}
                        departmentId={departmentId}
                        departmentName={departmentName}
                        siteId={siteId}
                        siteName={siteName}
                        companyId={companyId}
                        companyName={companyName}
                      >
                        <Switch>
                          <Route
                            path="/feed/news/:type(create)"
                            component={NewsEdit}
                          />
                          <Route
                            path="/feed/news/:id/:type(edit)"
                            component={NewsEdit}
                          />
                          <Route
                            exact
                            path="/feed/news/:id"
                            component={NewsArticle}
                          />
                          <Route
                            exact
                            path="/feed/press/:id"
                            component={PressArticle}
                          />
                          <Route
                            path="/feed/blog/:type(create)"
                            component={BlogEdit}
                          />
                          <Route
                            path="/feed/blog/:id/:type(edit)"
                            component={BlogEdit}
                          />
                          <Route path="/feed/blog/:id" component={Blog} />
                          <Route
                            path="/feed/story/:type(create)"
                            component={StoryEdit}
                          />
                          <Route
                            path="/feed/story/:id/:type(edit)"
                            component={StoryEdit}
                          />
                          <Route path="/feed/story/:id" component={Story} />
                          <Route exact path="/feed" component={Feed} />
                          <Route exact path="/feed/all" component={Feed} />
                          <Route exact path="/feed/topics" component={Feed} />

                          <Redirect to="/feed" />
                        </Switch>
                      </FeedProvider>
                    )
                  }
                </UserConsumer>
              </Route>
              <Route exact path="/people/:id" component={ProfileOverview} />
              <Route path="/hero-banner">
                <UserConsumer>
                  {({ divisionId, divisionName, countryId, countryName }) => (
                    <HeroBannerProvider
                      divisionId={divisionId}
                      divisionName={divisionName}
                      countryId={countryId}
                      countryName={countryName}
                    >
                      <Switch>
                        <Route
                          path="/hero-banner/:type(create)"
                          component={HeroBannerEdit}
                        />
                        <Route
                          path="/hero-banner/:id/:type(edit)"
                          component={HeroBannerEdit}
                        />
                        <Redirect to="/hero-banner/create" />
                      </Switch>
                    </HeroBannerProvider>
                  )}
                </UserConsumer>
              </Route>
              <Route path="/alert">
                <UserConsumer>
                  {({ divisionId, divisionName, countryId, countryName }) => (
                    <AlertProvider
                      divisionId={divisionId}
                      divisionName={divisionName}
                      countryId={countryId}
                      countryName={countryName}
                    >
                      <Switch>
                        <Route
                          path="/alert/:type(create)"
                          component={AlertEdit}
                        />
                        <Route exact path="/alert" component={Alert} />
                        <Redirect to="/alert" />
                      </Switch>
                    </AlertProvider>
                  )}
                </UserConsumer>
              </Route>
              <Route path="/topics/:type(create)" component={ChannelEdit} />
              <Route path="/topics/:id/:type(edit)" component={ChannelEdit} />
              <Route path="/some/:type(create)" component={SomeEdit} />
              <Route path="/some/:type(edit)" component={SomeEdit} />
              {user.isAdmin && (
                <Route
                  path={`${AvenueRoute.NewsletterBannerCreate}`}
                  component={NewsletterBannerCreate}
                />
              )}
              {user.isAdmin && (
                <Route
                  path={`${AvenueRoute.NewsletterBannerEdit}/:id`}
                  component={NewsletterBannerEdit}
                />
              )}
              <Route
                path="/productoftheweek/:type(create)"
                component={CreateOrEditProductOfTheWeek}
              />
              <Route
                path="/productoftheweek/:id/:type(edit)"
                component={CreateOrEditProductOfTheWeek}
              />
              <Route
                path={AvenueRoute.ProductOfTheWeekGallery}
                component={ProductOfTheWeekGallery}
              />

              <Route path="/topics/:id" component={Channels} />
              <Route exact path="/my-moment" component={MyMoment} />
              <Route
                path="/my-moment/:type(create)"
                component={CreateOrEditMyMoment}
              />
              <Route
                path="/my-moment/:type(edit)"
                component={CreateOrEditMyMoment}
              />
              <Route
                exact
                path="/campaign/crossword"
                component={CrossWordCampaign}
              />
              <Route exact path="/admin" component={Administration} />

              <Route
                path="/library-redirect/:id"
                component={LibraryIdRedirect}
              />

              {!redirects.isLoading && (
                <Switch>
                  {(redirects.redirectExact || []).map((redirect, i) => (
                    <Redirect
                      exact
                      path={redirect.from}
                      to={redirect.to}
                      key={`redirect-exact-${i}`}
                    />
                  ))}
                  {(redirects.redirectAll || []).map((redirect, i) => (
                    <RedirectAll
                      from={redirect.from}
                      to={redirect.to}
                      key={`redirect-all-${i}`}
                    />
                  ))}
                  <Route exact path="/library">
                    <UserConsumer>
                      {({ ...userContext }) => (
                        <Redirect to={`/library${userContext.path}`} />
                      )}
                    </UserConsumer>
                  </Route>
                  <Route path="/library">
                    <UserConsumer>
                      {({ firstLoad }) =>
                        firstLoad ? null : (
                          <Route path="/library/:route*" component={Library} />
                        )
                      }
                    </UserConsumer>
                  </Route>
                  <Route path="/library/:id/:childid?" component={Library} />
                  <Route path="/group-library/*:id?" component={Library} />
                  <Route path="/group-library">
                    <UserConsumer>
                      {({ firstLoad }) =>
                        firstLoad ? null : (
                          <Route
                            path="/group-library/:route*"
                            component={Library}
                          />
                        )
                      }
                    </UserConsumer>
                  </Route>
                  <Route exact path="*">
                    <Redirect to="/404" />
                  </Route>
                </Switch>
              )}
            </Switch>
          </main>
        </MyMomentProvider>
      </HomeBannerProvider>
    </GlobalWebcastProvider>
  );
};

const Root = () => {
  const [overrideTheme, setOverrideTheme] = useState(null);

  if (featureToggle(FeatureToggle.AssaFont) && !overrideTheme) {
    const _overrideTheme = createTheme({ enableAssaFont: true });
    setOverrideTheme(_overrideTheme);
  }

  return (
    <ThemeProvider theme={overrideTheme || theme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        dateFormats={{ keyboardDate: 'yyyy-MM-dd' }}
      >
        <WhitelistedUrlsProvider>
          <RedirectsProvider>
            <UserProvider>
              <SnackbarProvider>
                <AlertsProvider>
                  <NextArticleProvider>
                    <Router>
                      <AIChatProvider>
                        <>
                          <ScrollToTop />
                          <AppInsights />
                          <Outline />
                          <HistoryListener />
                          <UserConsumer>
                            {({
                              firstLoad,
                              blockedUser,
                              isManager,
                              divisionId,
                              departmentId,
                              userId,
                              regionId,
                              nouser,
                              country,
                              showSurvey,
                            }) => (
                              <>
                                {showSurvey && <SurveyPopup />}
                                {nouser && <Redirect to="/nouser" />}
                                <UserMetaGA
                                  division={divisionId}
                                  userId={userId}
                                  department={departmentId}
                                  regionId={regionId}
                                  isManager={isManager}
                                  countryName={country}
                                />
                                {firstLoad || blockedUser ? null : <Modals />}
                                {blockedUser && (
                                  <ForceRedirect url="/public/unauthorized-user" />
                                )}
                              </>
                            )}
                          </UserConsumer>
                          <Switch>
                            <Route
                              path="/portals/:portalUrl/:pageId?/:type(edit|create|create-block)?"
                              component={Portals}
                            />
                            <Route
                              path="/portals-redirect/:portalId/:pageId?"
                              component={PortalsRedirect}
                            />
                            <Route
                              path="/innovation"
                              render={() => (
                                <ForceRedirect url="/portals/innovation" />
                              )}
                            />
                            <Route
                              path="/insights-database"
                              render={() => (
                                <ForceRedirect
                                  url={`/portals/innovation/${insightsDbPageId}`}
                                />
                              )}
                            />
                            <Route
                              path="/public"
                              render={({ location }) => (
                                <ForceRedirect url={location.pathname} />
                              )}
                            />
                            <Route path="/" component={Avenue} />
                            <Redirect to="/404" />
                          </Switch>
                          <Footer />
                          <Hotjar />
                        </>
                      </AIChatProvider>
                    </Router>
                  </NextArticleProvider>
                </AlertsProvider>
              </SnackbarProvider>
            </UserProvider>
          </RedirectsProvider>
        </WhitelistedUrlsProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default Root;
