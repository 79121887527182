import Box from 'components/Box';
import Button from 'components/Button';
import ButtonModalWrapper from 'components/Button/ButtonModalWrapper';
import Title from 'components/Title';
import React from 'react';

import { Consumer } from '..';
import DeleteButton from './DeleteButton';
import PublishButton from './PublishButton';
import SaveAsDraftButton from './SaveAsDraftButton';

interface PublishOptionsProps {
  children?: React.ReactElement | React.ReactElement[];
  onCancel?: string;
  subject?: string;
  hideDelete?: boolean;
  deleteModalChildren?: boolean;
  deleteButtonText?: 'Unpublish' | 'Delete';
  showDraftButton?: boolean;
}

const yesButton = {
  color: 'black',
  title: 'Leave',
};

const noButton = {
  color: 'white',
  title: 'Cancel',
};

const PublishOptions = ({
  children,
  onCancel,
  subject,
  hideDelete,
  deleteModalChildren,
  deleteButtonText,
  showDraftButton,
}: PublishOptionsProps) => (
  <Consumer>
    {({
      updatedArticle: {
        publishedInDivisions,
        publishedInSegments,
        publishedInCountries,
        publishedInDepartments,
        publishedInSites,
        publishedInCompanies,
      },
      isCreating,
      onDeleteArticle,
      onSaveArticle,
      onAfterCancel,
    }) => (
      <>
        {children ? <Title size="small">Publishing options</Title> : <></>}
        {children ? <Box>{children}</Box> : <></>}
        <div className="m-t-4 article-button-wrapper">
          <div className="confirm-action-wrapper">
            <PublishButton
              countries={publishedInCountries}
              divisions={publishedInDivisions}
              segments={publishedInSegments}
              departments={publishedInDepartments}
              sites={publishedInSites}
              companies={publishedInCompanies}
              onSaveArticle={() => onSaveArticle(false)}
              isCreating={isCreating || showDraftButton}
              buttonStyle={null}
            />
            <ButtonModalWrapper
              title="Are you sure you want to leave this page? Any unsaved changes will be lost."
              modalChildren={null}
              yesButton={yesButton}
              noButton={noButton}
            >
              <Button
                onClick={onAfterCancel}
                className="sm-m-b-2"
                color="transparent"
              >
                Cancel
              </Button>
            </ButtonModalWrapper>
          </div>
          {showDraftButton && (
            <SaveAsDraftButton
              countries={publishedInCountries}
              divisions={publishedInDivisions}
              segments={publishedInSegments}
              departments={publishedInDepartments}
              sites={publishedInSites}
              companies={publishedInCompanies}
              onSaveArticle={() => onSaveArticle(true)}
              buttonStyle={null}
            />
          )}
          {hideDelete !== true && showDraftButton !== true && (
            <DeleteButton
              onDeleteArticle={onDeleteArticle}
              modalChildren={deleteModalChildren}
              onCancel={onCancel}
              isCreating={isCreating}
              subject={subject}
              text={deleteButtonText}
            />
          )}
        </div>
      </>
    )}
  </Consumer>
);

export default PublishOptions;
