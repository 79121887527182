import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import { Icon } from 'componentsNew';

type ChipProps = MuiChipProps;

const Chip = (props: ChipProps) => {
  return (
    <MuiChip
      deleteIcon={<Icon type="xMark" size={props.size === 'small' ? 16 : 20} />}
      {...props}
      sx={[
        (theme) => ({
          '&.MuiChip-sizeSmall': {
            height: '28px',
            fontSize: theme.typography.caption.fontSize,
            lineHeight: theme.typography.caption.lineHeight,
          },
          '&.MuiChip-sizeMedium': {
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
          },

          '&.MuiChip-filled': {
            '&.MuiChip-filledDefault': {
              color: theme.colors.text.inversePrimary,
              backgroundColor: theme.colors.surface.inverseSecondary,
              '.MuiChip-deleteIcon svg': {
                color: theme.colors.icon.inversePrimary,
              },
            },
            '&.MuiChip-filledPrimary': {
              color: theme.colors.text.inversePrimary,
              backgroundColor: theme.colors.surface.actionPrimaryDefault,
              '.MuiChip-deleteIcon svg': {
                color: theme.colors.icon.inversePrimary,
              },
            },
          },
          '&.MuiChip-outlined': {
            '&.MuiChip-outlinedDefault': {
              color: theme.colors.text.tertiary,
              backgroundColor: theme.colors.surface.secondary,
              borderColor: theme.colors.border.surfacePrimary,
              '.MuiChip-deleteIcon svg': {
                color: theme.colors.icon.secondary,
              },
            },
            '&.MuiChip-outlinedPrimary': {
              color: theme.colors.text.actionDefault,
              backgroundColor: theme.colors.surface.actionSecondaryDefault,
              borderColor: theme.colors.border.actionSecondaryDefault,
              '.MuiChip-deleteIcon svg': {
                color: theme.colors.icon.brandBase,
              },
            },
          },
        }),
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
};

export { Chip };
