import './GoodToKnowTitle.scss';

import Text from 'components/Editable/Text';
import InfoBox from 'components/InfoBox';
import _Title from 'components/Title';

import { Consumer } from '..';

const infoMessage =
  'The information box and the "Good to know" headline will appear on the right side of your article body.';

interface GoodToKnowTitleProps {
  editable?: boolean;
  placeholder?: string;
  allowedCharacters?: string;
  fieldName?: string;
}

const GoodToKnowTitle = ({
  editable,
  placeholder,
  allowedCharacters,
  fieldName = 'goodToKnowTitle',
}: GoodToKnowTitleProps) => (
  <Consumer>
    {({
      article,
      onChangeArticle,
      validationParameters: {
        [fieldName]: { maxLength },
      },
      showInitialError,
    }) => {
      if (editable) {
        return (
          <div className="good-to-know-title--editable">
            <Text
              wrapper={{
                element: 'h2',
              }}
              placeholder={placeholder}
              onBlur={(value: any) => onChangeArticle({ [fieldName]: value })}
              validators={[]}
              showInitialError={showInitialError}
              content={article[fieldName]}
              maxLength={maxLength}
              allowedCharacters={allowedCharacters}
            />
            <InfoBox message={infoMessage} />
          </div>
        );
      }
      return (
        <div className="good-to-know-title--non-editable">
          <_Title tag="h2" size="small">
            {article[fieldName]}
          </_Title>
          <InfoBox message={infoMessage} />
        </div>
      );
    }}
  </Consumer>
);

export default GoodToKnowTitle;
