import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon } from 'componentsNew';
import React from 'react';
import { useMemo } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';

export type AlertBarProps = {
  open: boolean;
  type?: 'information' | 'success' | 'warning' | 'critical';
  size?: 'small' | 'large';
  snackbar?: boolean;
  snackbarAutoHideDuration?: number;
  title?: string;
  text?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  children?: React.ReactNode | React.ReactNode[];
  onClose?: () => void;
};

const AlertBar = React.forwardRef(
  (
    {
      open,
      type = 'information',
      size = 'small',
      snackbar,
      snackbarAutoHideDuration = 5000,
      title,
      text,
      icon,
      fullWidth,
      sx,
      children,
      onClose,
    }: AlertBarProps,
    ref
  ) => {
    const theme = useTheme();

    const settings: {
      title: string;
      icon: React.ReactNode;
      backgroundColor: string;
      accentColor: string;
      typographyTitle: CSSProperties;
      typographyText: CSSProperties;
    } = useMemo(() => {
      const isLarge = size === 'large';

      const typography = {
        typographyTitle: isLarge
          ? theme.typography.body1
          : theme.typography.body2,
        typographyText: theme.typography.body2,
      };

      switch (type) {
        case 'success':
          return {
            title: title ? title : translations.successful,
            icon: icon || (
              <Icon
                type="checkCircleFilled"
                color="success"
                size={isLarge ? 32 : 24}
              />
            ),
            backgroundColor: theme.colors.surface.success,
            accentColor: theme.colors.icon.success,
            ...typography,
          };
        case 'warning':
          return {
            title: title ? title : translations.warning,
            icon: icon || (
              <Icon
                type="bellAlertFilled"
                color="warning"
                size={isLarge ? 32 : 24}
              />
            ),
            backgroundColor: theme.colors.surface.warning,
            accentColor: theme.colors.icon.warning,
            ...typography,
          };
        case 'critical':
          return {
            title: title ? title : translations.error,
            icon: icon || (
              <Icon
                type="exclamationTriangleFilled"
                color="critical"
                size={isLarge ? 32 : 24}
              />
            ),
            backgroundColor: theme.colors.surface.critical,
            accentColor: theme.colors.icon.critical,
            ...typography,
          };
        default:
          return {
            title: title ? title : translations.information,
            icon: icon || (
              <Icon
                type="informationCircleFilled"
                color="brandBase"
                size={size === 'large' ? 32 : 24}
              />
            ),
            backgroundColor: theme.colors.surface.informative,
            accentColor: theme.colors.icon.brandBase,
            ...typography,
          };
      }
    }, [
      size,
      type,
      title,
      icon,
      theme.colors.surface.success,
      theme.colors.surface.warning,
      theme.colors.surface.critical,
      theme.colors.surface.informative,
      theme.colors.icon.success,
      theme.colors.icon.warning,
      theme.colors.icon.critical,
      theme.colors.icon.brandBase,
      theme.typography.body1,
      theme.typography.body2,
    ]);

    const alertBarElement = useMemo(
      () => (
        <Box
          ref={ref}
          sx={[
            () => ({
              backgroundColor: settings.accentColor,
              paddingLeft: '0.25rem',
              borderRadius: '0.5rem',
              width: fullWidth ? '100%' : { xs: '100%', sm: 'fit-content' },
            }),
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        >
          <Stack
            sx={(theme) => ({
              flexDirection: 'row',
              columnGap: theme.spacing('xs'),
              padding: theme.spacing('xs'),
              borderRadius: '0.25rem',
              backgroundColor: settings.backgroundColor,
              textAlign: 'start',
            })}
          >
            {settings.icon}
            <Stack>
              {title && (
                <Typography
                  sx={(theme) => ({
                    typography: settings.typographyTitle,
                    color: theme.colors.text.primary,
                    wordBreak: 'break-word',
                    fontWeight: 'bold',
                    lineHeight: 1.5,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  })}
                >
                  {title}
                </Typography>
              )}
              {text && (
                <Typography
                  sx={(theme) => ({
                    typography: settings.typographyText,
                    color: Boolean(title)
                      ? theme.colors.text.secondary
                      : theme.colors.text.primary,
                    wordBreak: 'break-word',
                    lineHeight: 1.5,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  })}
                >
                  {text}
                </Typography>
              )}
              {children}
            </Stack>
            {onClose && (
              <IconButton
                size="small"
                aria-label={translations.close}
                onClick={onClose}
                sx={{
                  alignSelf: 'baseline',
                  marginLeft: 'auto',
                  padding: 0,
                  '&.MuiButtonBase-root.MuiIconButton-root:hover': {
                    backgroundColor: 'unset',
                  },
                }}
              >
                <Icon type="xMark" color="secondary" />
              </IconButton>
            )}
          </Stack>
        </Box>
      ),
      [children, fullWidth, onClose, ref, settings, sx, text, title]
    );

    if (snackbar) {
      return (
        <Snackbar
          open={open}
          onClose={onClose}
          autoHideDuration={snackbarAutoHideDuration}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          sx={(theme) => ({
            margin: { xs: theme.spacing('xxs'), sm: 'unset' },
          })}
        >
          {alertBarElement}
        </Snackbar>
      );
    }
    return (
      <TransitionGroup>
        {open && <Collapse>{alertBarElement}</Collapse>}
      </TransitionGroup>
    );
  }
);

export { AlertBar };
