import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import notFoundImage from 'assets/images/not-found.jpg';
import { Badge, Checkbox, Icon, TableCellWithSort } from 'componentsNew';
import { ReactComponent as EmojiAngryOutlinedIcon } from 'componentsNew/Icon/featured/EmojiAngryOutlined.svg';
import { ReactComponent as EmojiHappyOutlinedIcon } from 'componentsNew/Icon/featured/EmojiHappyOutlined.svg';
import { ReactComponent as EmojiNeutralOutlinedIcon } from 'componentsNew/Icon/featured/EmojiNeutralOutlined.svg';
import { useCallback, useMemo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonChangeManageLibrarySort,
  GAonClickManageLibraryPage,
} from 'utils/analytics';

import * as helpers from './helpers';
import { ArticleStatus } from './ManageLibraryFilter';
import { ManageLibraryTableSkeleton } from './ManageLibraryTableSkeleton';

const elementId = 'profile-manage-library-table';

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum ManageLibraryTableField {
  Title = 'title',
  ExpiryDate = 'expiryDate',
  DeletionDate = 'deletionDate',
  ModifiedDate = 'modifiedDate',
  BrokenLinksCount = 'brokenLinksCount',
  InformationOwner = 'informationOwner',
}

export type ManageLibraryTableItem = {
  id: string;
  title: string;
  expiryDate?: string;
  deletionDate?: string;
  modifiedDate?: string;
  hasSubPages: boolean;
  brokenLinksCount?: number;
  feedback?: {
    helpfulCount: number;
    notHelpfulCount: number;
    neutralCount: number;
  };
  informationOwner?: {
    name: string;
    oid: string;
  };
  linkPath: string;
};

type ManageLibraryTableProps = {
  sx?: SxProps<Theme>;
  isLoading: boolean;
  items: ManageLibraryTableItem[];
  filter: {
    isAdmin: boolean;
    articleStatus: ArticleStatus;
  };
  sort: {
    field: ManageLibraryTableField;
    order: SortOrder;
  };
  selection: {
    byId: Record<string, boolean>;
    isAllSelected: boolean;
    isSomeSelected: boolean;
  };
  onSortChange: (sort: {
    field: ManageLibraryTableField;
    order: SortOrder;
  }) => void;
  onSelectionChange: (selection: {
    byId: Record<string, boolean>;
    isAllSelected: boolean;
    isSomeSelected: boolean;
  }) => void;
};

const ManageLibraryTable = ({
  sx,
  isLoading,
  items,
  filter,
  sort,
  selection,
  onSortChange,
  onSelectionChange,
}: ManageLibraryTableProps) => {
  const handleSortClick = useCallback(
    (field: ManageLibraryTableField) => {
      onSortChange({
        field: field,
        order:
          sort?.field === field && sort.order === SortOrder.Asc
            ? SortOrder.Desc
            : SortOrder.Asc,
      });
      GAonChangeManageLibrarySort(field);
    },
    [onSortChange, sort]
  );

  const handleItemSelectionChange = useCallback(
    (id: string, checked: boolean) => {
      const selectionById = { ...selection.byId, [id]: checked };
      onSelectionChange({
        byId: selectionById,
        isSomeSelected: items.some((item) => selectionById[item.id]),
        isAllSelected: !items.some((item) => !selectionById[item.id]),
      });
    },
    [selection.byId, items, onSelectionChange]
  );

  const handleBulkSelectionChange = useCallback(
    (checked: boolean) => {
      const byId = items.reduce((selectionById, item) => {
        selectionById[item.id] = checked;
        return selectionById;
      }, {} as Record<string, boolean>);

      onSelectionChange({
        byId,
        isSomeSelected: checked,
        isAllSelected: checked,
      });
    },
    [items, onSelectionChange]
  );

  const noContentMessage = useMemo(() => {
    if (filter.isAdmin) {
      return translations.manageContentEmpty;
    }
    if (filter.articleStatus === ArticleStatus.Published) {
      return translations.manageContentEmptyLibraryPublished;
    }
    if (filter.articleStatus === ArticleStatus.Unpublished) {
      return translations.manageContentEmptyLibraryUnpublished;
    }
    if (filter.articleStatus === ArticleStatus.Drafts) {
      return translations.manageContentEmptyLibraryDrafts;
    }
    return translations.manageContentEmpty;
  }, [filter.articleStatus, filter.isAdmin]);

  if (isLoading) {
    return <ManageLibraryTableSkeleton />;
  }

  if (!items.length) {
    return (
      <Stack alignItems="center" padding="1rem">
        <Box component="img" src={notFoundImage} maxHeight="300px" />
        <Typography marginTop={1}>{noContentMessage}</Typography>
      </Stack>
    );
  }

  return (
    <TableContainer sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Table id={elementId} aria-label={translations.profileManageLibrary}>
        <TableHead>
          <TableRow>
            <TableCell width="3.5rem">
              <Checkbox
                checked={selection.isAllSelected || false}
                indeterminate={
                  selection.isSomeSelected && !selection.isAllSelected
                }
                onChange={(
                  _e: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => handleBulkSelectionChange(checked)}
              />
            </TableCell>
            <TableCellWithSort
              width="30%"
              sorted={sort?.field === ManageLibraryTableField.Title}
              onSort={() => handleSortClick(ManageLibraryTableField.Title)}
            >
              {translations.manageContentTitle}
            </TableCellWithSort>
            {filter.articleStatus === ArticleStatus.Published && (
              <>
                <TableCellWithSort
                  sorted={sort?.field === ManageLibraryTableField.ExpiryDate}
                  onSort={() =>
                    handleSortClick(ManageLibraryTableField.ExpiryDate)
                  }
                >
                  {translations.manageContentExpiryDate}
                </TableCellWithSort>
                {filter.isAdmin && (
                  <TableCellWithSort
                    sorted={
                      sort?.field === ManageLibraryTableField.InformationOwner
                    }
                    onSort={() =>
                      handleSortClick(ManageLibraryTableField.InformationOwner)
                    }
                  >
                    {translations.manageContentInformationOwner}
                  </TableCellWithSort>
                )}
                <TableCell align="center">
                  {translations.manageContentBrokenLinks}
                </TableCell>
                <TableCell align="center">
                  <EmojiAngryOutlinedIcon />
                </TableCell>
                <TableCell align="center">
                  <EmojiNeutralOutlinedIcon />
                </TableCell>
                <TableCell align="center">
                  <EmojiHappyOutlinedIcon />
                </TableCell>
              </>
            )}
            {filter.articleStatus === ArticleStatus.Unpublished && (
              <>
                <TableCellWithSort
                  sorted={sort?.field === ManageLibraryTableField.DeletionDate}
                  onSort={() =>
                    handleSortClick(ManageLibraryTableField.DeletionDate)
                  }
                >
                  {translations.manageContentDeletionDate}
                </TableCellWithSort>
                {filter.isAdmin && (
                  <TableCellWithSort
                    sorted={
                      sort?.field === ManageLibraryTableField.InformationOwner
                    }
                    onSort={() =>
                      handleSortClick(ManageLibraryTableField.InformationOwner)
                    }
                  >
                    {translations.manageContentInformationOwner}
                  </TableCellWithSort>
                )}
              </>
            )}
            {filter.articleStatus === ArticleStatus.Drafts && (
              <>
                <TableCellWithSort
                  sorted={sort?.field === ManageLibraryTableField.ModifiedDate}
                  onSort={() =>
                    handleSortClick(ManageLibraryTableField.ModifiedDate)
                  }
                >
                  {translations.manageContentModifiedDate}
                </TableCellWithSort>
                {filter.isAdmin && (
                  <TableCellWithSort
                    sorted={
                      sort?.field === ManageLibraryTableField.InformationOwner
                    }
                    onSort={() =>
                      handleSortClick(ManageLibraryTableField.InformationOwner)
                    }
                  >
                    {translations.manageContentInformationOwner}
                  </TableCellWithSort>
                )}
              </>
            )}
            {filter.articleStatus === ArticleStatus.Ownerless &&
              filter.isAdmin && (
                <>
                  <TableCellWithSort
                    sorted={sort?.field === ManageLibraryTableField.ExpiryDate}
                    onSort={() =>
                      handleSortClick(ManageLibraryTableField.ExpiryDate)
                    }
                  >
                    {translations.manageContentExpiryDate}
                  </TableCellWithSort>
                  <TableCellWithSort
                    sorted={
                      sort?.field === ManageLibraryTableField.InformationOwner
                    }
                    onSort={() =>
                      handleSortClick(ManageLibraryTableField.InformationOwner)
                    }
                  >
                    {translations.manageContentInformationOwner}
                  </TableCellWithSort>
                </>
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={`${item.id}-${index}`}
              id={`${elementId}-row-${index}`}
            >
              <TableCell width="3.5rem">
                <Checkbox
                  checked={selection.byId[item.id] || false}
                  onChange={(
                    _e: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => handleItemSelectionChange(item.id, checked)}
                />
              </TableCell>
              <TableCell width="30%">
                <Stack>
                  {filter.articleStatus === ArticleStatus.Unpublished ? (
                    <Typography variant="body2" fontWeight="bold">
                      {helpers.getFormattedTitle(item.title)}
                    </Typography>
                  ) : (
                    <Link
                      variant="body2"
                      fontWeight="bold"
                      to={item.linkPath}
                      component={ReactRouterLink}
                      sx={{ textDecoration: 'none' }}
                      onClick={() => GAonClickManageLibraryPage(item.title)}
                    >
                      {helpers.getFormattedTitle(item.title)}
                    </Link>
                  )}
                  {item.hasSubPages && (
                    <Typography
                      variant="caption"
                      sx={(theme) => ({ color: theme.colors.text.tertiary })}
                    >
                      {translations.manageContentHasSubPages}
                    </Typography>
                  )}
                </Stack>
              </TableCell>
              {filter.articleStatus === ArticleStatus.Published && (
                <>
                  <TableCell>
                    {!item.expiryDate ? (
                      translations.notAvailable
                    ) : helpers.isDateSoon(item.expiryDate) ? (
                      <Badge
                        size="small"
                        color="warning"
                        icon={<Icon type="exclamationTriangleFilled" />}
                        label={helpers.getFormattedDate(item.expiryDate)}
                        title={translations.manageContentLibraryExpiryInfo}
                      />
                    ) : (
                      <Badge
                        size="small"
                        color="default"
                        variant="outlined"
                        label={helpers.getFormattedDate(item.expiryDate)}
                      />
                    )}
                  </TableCell>
                  {filter.isAdmin && (
                    <TableCell>
                      {item.informationOwner ? (
                        <Link
                          to={`/people/${item.informationOwner.oid}`}
                          component={ReactRouterLink}
                          sx={{ textDecoration: 'none' }}
                        >
                          {item.informationOwner.name}
                        </Link>
                      ) : (
                        translations.notAvailable
                      )}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    {item.brokenLinksCount && item.brokenLinksCount > 0 ? (
                      <Badge
                        size="small"
                        color="error"
                        variant="outlined"
                        label={item.brokenLinksCount}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell align="center">
                    {!item.feedback ? (
                      translations.notAvailable
                    ) : item.feedback.notHelpfulCount > 0 ? (
                      <Typography variant="caption" fontWeight="bold">
                        {item.feedback?.notHelpfulCount}
                      </Typography>
                    ) : (
                      <Icon type="minus" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {!item.feedback ? (
                      translations.notAvailable
                    ) : item.feedback.neutralCount > 0 ? (
                      <Typography variant="caption" fontWeight="bold">
                        {item.feedback?.neutralCount}
                      </Typography>
                    ) : (
                      <Icon type="minus" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {!item.feedback ? (
                      translations.notAvailable
                    ) : item.feedback.helpfulCount > 0 ? (
                      <Typography variant="caption" fontWeight="bold">
                        {item.feedback?.helpfulCount}
                      </Typography>
                    ) : (
                      <Icon type="minus" />
                    )}
                  </TableCell>
                </>
              )}
              {filter.articleStatus === ArticleStatus.Unpublished && (
                <>
                  <TableCell>
                    {!item.deletionDate ? (
                      translations.notAvailable
                    ) : helpers.isDateSoon(item.deletionDate) ? (
                      <Badge
                        size="small"
                        color="warning"
                        icon={<Icon type="exclamationTriangleFilled" />}
                        label={helpers.getFormattedDate(item.deletionDate)}
                        title={translations.manageContentLibraryDeletionInfo}
                      />
                    ) : (
                      <Badge
                        size="small"
                        color="default"
                        variant="outlined"
                        label={helpers.getFormattedDate(item.deletionDate)}
                      />
                    )}
                  </TableCell>

                  {filter.isAdmin && (
                    <TableCell>
                      {item.informationOwner ? (
                        <Link
                          to={`/people/${item.informationOwner.oid}`}
                          component={ReactRouterLink}
                          sx={{ textDecoration: 'none' }}
                        >
                          {item.informationOwner.name}
                        </Link>
                      ) : (
                        translations.notAvailable
                      )}
                    </TableCell>
                  )}
                </>
              )}
              {filter.articleStatus === ArticleStatus.Drafts && (
                <>
                  <TableCell>
                    {!item.modifiedDate ? (
                      translations.notAvailable
                    ) : (
                      <Badge
                        size="small"
                        color="default"
                        variant="outlined"
                        label={helpers.getFormattedDate(item.modifiedDate)}
                      />
                    )}
                  </TableCell>
                  {filter.isAdmin && (
                    <TableCell>
                      {item.informationOwner ? (
                        <Link
                          to={`/people/${item.informationOwner.oid}`}
                          component={ReactRouterLink}
                          sx={{ textDecoration: 'none' }}
                        >
                          {item.informationOwner.name}
                        </Link>
                      ) : (
                        translations.notAvailable
                      )}
                    </TableCell>
                  )}
                </>
              )}
              {filter.articleStatus === ArticleStatus.Ownerless &&
                filter.isAdmin && (
                  <>
                    <TableCell>
                      {!item.expiryDate ? (
                        translations.notAvailable
                      ) : helpers.isDateSoon(item.expiryDate) ? (
                        <Badge
                          size="small"
                          color="warning"
                          icon={<Icon type="exclamationTriangleFilled" />}
                          label={helpers.getFormattedDate(item.expiryDate)}
                          title={translations.manageContentLibraryExpiryInfo}
                        />
                      ) : (
                        <Badge
                          size="small"
                          color="default"
                          variant="outlined"
                          label={helpers.getFormattedDate(item.expiryDate)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {item.informationOwner ? (
                        <Link
                          to={`/people/${item.informationOwner.oid}`}
                          component={ReactRouterLink}
                          sx={{ textDecoration: 'none' }}
                        >
                          {item.informationOwner.name}
                        </Link>
                      ) : (
                        translations.notAvailable
                      )}
                    </TableCell>
                  </>
                )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { ManageLibraryTable };
