import { CommentDataType } from 'enums';
import settings from 'settings';
import { NotificationDTO } from 'types/Portal';
import createRequest from 'utils/api/requestFactory';

const { feedback } = settings.api;

export const getFeedback = (id: any, query: any) =>
  createRequest({
    method: 'get',
    url: `${feedback}/feedback/my/${id}/${query}`,
    errorMessages: {
      '*': 'Failed to get feedback, please contact support for assistance.',
    },
  });

export const getFeedbackList = (ids: string[]) =>
  createRequest({
    method: 'get',
    url: `${feedback}/feedback-library-articles?articleIds=${ids}`,
    errorMessages: {
      '*': 'Failed to get feedback, please contact support for assistance.',
    },
  });

export const sendFeedback = (id: any, attributes: any) =>
  createRequest({
    method: 'post',
    url: `${feedback}/feedback/my/${id}`,
    data: {
      data: {
        type: 'feedback',
        attributes,
      },
    },
    errorMessages: {
      '*': 'Failed to send feedback, please contact support for assistance.',
    },
  });

export const sendComment = (
  dataType = CommentDataType.Comment,
  attributes: any
) =>
  createRequest({
    method: 'post',
    url: `${feedback}/comments`,
    data: {
      data: {
        type: dataType,
        attributes: {
          targetEntityId: attributes.targetEntityId,
          text: attributes.text,
          authorId: attributes.authorId,
          editorId: attributes.editorId,
          itemType: attributes.type,
          itemSubType: attributes.itemSubType,
          articleId: attributes.articleId,
          additionalFeedbackReceivers: attributes.additionalFeedbackReceivers,
        },
      },
    },
    errorMessages: {
      '*': 'Failed to send comment, please contact support for assistance.',
    },
  });

export const updateComment = (
  dataType = CommentDataType.Comment,
  id: any,
  attributes: any
) =>
  createRequest({
    method: 'patch',
    url: `${feedback}/comments/${id}`,
    data: {
      data: {
        type: dataType,
        attributes,
      },
    },
    errorMessages: {
      '*': 'Failed to update comment, please contact support for assistance.',
    },
  });

export const getComments = (targetEntityId: any, query = '') =>
  createRequest({
    method: 'get',
    url: `${feedback}/comments/${targetEntityId}${query}`,
    errorMessages: {
      '*': 'Failed to send comment, please contact support for assistance.',
    },
  });

export const deleteComment = (
  dataType = CommentDataType.Comment,
  id: any,
  articleId: string
) =>
  createRequest({
    method: 'delete',
    url: `${feedback}/comments/${id}`,
    data: {
      data: {
        type: dataType,
        attributes: {
          articleId,
        },
      },
    },
    errorMessages: {
      '*': 'Failed to delete comment, please contact support for assistance.',
    },
  });

export const sendLike = (targetEntityId: any) =>
  createRequest({
    method: 'post',
    url: `${feedback}/likes`,
    data: {
      data: {
        type: 'likes',
        attributes: {
          targetEntityId,
        },
      },
    },
    errorMessages: {
      '*': 'Failed to send like, please contact support for assistance.',
    },
  });

export const getLikes = (targetEntityId: any) =>
  createRequest({
    method: 'get',
    url: `${feedback}/likes/${targetEntityId}`,
    errorMessages: {
      '*': 'Failed to get likes, please contact support for assistance.',
    },
  });

export const deleteLike = (targetEntityId: any) =>
  createRequest({
    method: 'delete',
    url: `${feedback}/likes/${targetEntityId}`,
    errorMessages: {
      '*': 'Failed to delete like, please contact support for assistance.',
    },
  });

export const getLikesAndComments = (articleIds: any) =>
  createRequest({
    method: 'get',
    url: `${feedback}/likes-comments?articleIds=${articleIds}`,
    errorMessages: {
      '*': 'Failed to retrieve likes and comments',
    },
  });

export const getNotifications = (userId: string) =>
  createRequest({
    method: 'get',
    url: `${feedback}/notifications/${userId}?page[limit]=5`,
  }).then(mapToNotificationDTO);

export const deleteNotification = (notificationId: string) =>
  createRequest({
    method: 'delete',
    url: `${feedback}/notifications/${notificationId}`,
  });

export const markNotificationAsRead = (
  notificationId: string,
  readState: boolean
) =>
  createRequest({
    method: 'post',
    url: `${feedback}/notifications/mark-as-read/${notificationId}`,
    data: {
      data: {
        hasBeenRead: readState,
      },
    },
  });

export const markAllNotificationAsRead = () =>
  createRequest({
    method: 'post',
    url: `${feedback}/notifications/mark-all-as-read`,
  });

export const getPageViews = (pagePath: string) =>
  createRequest({
    method: 'get',
    url: `${feedback}/page-views?pagePath=${pagePath}`,
  });

export const getLikesCommentsAndViewsCount = (pagePaths: any) =>
  createRequest({
    method: 'get',
    url: `${feedback}/likes-comments-views?pagePaths=${pagePaths}`,
    errorMessages: {
      '*': 'Failed to retrieve likes and comments',
    },
  });

export const sendAIFeedback = (data: {
  question: string;
  answer: string;
  divisionId?: string;
  countryId?: string;
  siteId?: string;
  segmentId?: string;
  companyId?: string;
  departmentId?: string;
  regionId?: string;
}) => {
  return createRequest({
    method: 'post',
    url: `${feedback}/ai-feedback`,
    data: { data },
    errorMessages: {
      '*': 'Failed to send feedback, please contact support for assistance.',
    },
  });
};

const mapToNotificationDTO = (response: any) =>
  response.data.data.map((data: any) => ({
    personId: data.attributes.personId,
    id: data.id,
    type: data.attributes.notificationType,
    body: data.attributes.body,
    read: data.attributes.hasBeenRead,
    timestamp: data.attributes.timestamp,
    title: data.attributes.title,
    linkUrl: data.attributes.linkUrl,
  })) as NotificationDTO[];
