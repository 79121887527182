import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as organizationApi from 'api/organization';
import * as organizationTransformers from 'api/organization/transformers';
import { DivisionWithCountries } from 'api/organization/types';
import { useUser } from 'components/Context/User';
import { AlertBar } from 'componentsNew';
import { AvenueRoute } from 'constants/routes';
import { Page } from 'layoutNew';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'translations';
import { Department, RequestStatus, Segment } from 'types';
import { GAonClickProfileTab } from 'utils/analytics';

import { ManageBanners } from './ManageBanners/ManageBanners';
import { ManageFeed } from './ManageFeed/ManageFeed';
import { ManageInsightsDatabase } from './ManageInsightsDatabase/ManageInsightsDatabase';
import { ManageLibrary } from './ManageLibrary/ManageLibrary';
import { Preferences } from './Preferences/Preferences';
import { ProfileCard } from './ProfileCard/ProfileCard';
import { ProfileInformation } from './ProfileInformation/ProfileInformation';
import { ProfileTabItem, ProfileTabs } from './ProfileTabs';
import { Subscriptions } from './Subscriptions/Subscriptions';

const elementId = 'profile';

const Profile = () => {
  const [preferencesFormData, setPreferencesFormData] = useState<{
    divisionsWithCountries: DivisionWithCountries[];
    segments: Segment[];
    departments: Department[];
  } | null>(null);

  const [fetchPreferencesFormDataStatus, setFetchPreferencesFormDataStatus] =
    useState<RequestStatus>(RequestStatus.Idle);

  const user = useUser();
  const history = useHistory();
  const location = useLocation();
  const mountedRef = useRef<boolean>(true);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const fetchPreferencesFormData = useCallback(async () => {
    const formData: {
      divisionsWithCountries: DivisionWithCountries[];
      segments: Segment[];
      departments: Department[];
    } = {
      divisionsWithCountries: [],
      segments: [],
      departments: [],
    };
    try {
      const response = await organizationApi.getDivisions();
      const transformed =
        organizationTransformers.divisionsResponseToDivisionsWithCountries(
          response
        );
      formData.divisionsWithCountries = transformed;
    } catch {
      if (!mountedRef.current) return;
      setFetchPreferencesFormDataStatus(RequestStatus.Failure);
    }
    try {
      const response = await organizationApi.getSegments();
      const transformed =
        organizationTransformers.segmentsResponseToSegments(response);
      formData.segments = transformed;
    } catch {
      if (!mountedRef.current) return;
      setFetchPreferencesFormDataStatus(RequestStatus.Failure);
    }
    try {
      const response = await organizationApi.getDepartments();
      const transformed =
        organizationTransformers.departmentsResponseToDepartments(response);
      formData.departments = transformed;
    } catch {
      if (!mountedRef.current) return;
      setFetchPreferencesFormDataStatus(RequestStatus.Failure);
    }
    if (!mountedRef.current) return;
    setPreferencesFormData(formData);
  }, []);

  const tabItems = useMemo(() => {
    const _tabItems: ProfileTabItem[] = [
      {
        id: `${elementId}-preferences-tab`,
        label: translations.profilePreferences,
        content: <Preferences formData={preferencesFormData} />,
        path: AvenueRoute.ProfilePreferences,
        gaName: 'Preferences',
      },
      {
        id: `${elementId}-subscriptions-tab`,
        label: translations.profileSubscriptions,
        content: <Subscriptions />,
        path: AvenueRoute.ProfileSubscriptions,
        gaName: 'Subscriptions',
      },
      ...(isDesktop
        ? [
            {
              id: `${elementId}-manage-library-tab`,
              label: translations.profileManageLibrary,
              content: <ManageLibrary />,
              path: AvenueRoute.ProfileManageLibrary,
              gaName: 'Library Pages',
            },
          ]
        : []),
      ...(isDesktop &&
      user.roles &&
      (user.roles.includes('AvenueGlobalEditor') ||
        user.roles.includes('AvenueBlogEditor') ||
        user.roles.includes('AvenueSuperuser'))
        ? [
            {
              id: `${elementId}-manage-feed-tab`,
              label: translations.profileManageFeed,
              content: <ManageFeed />,
              path: AvenueRoute.ProfileManageFeed,
              gaName: 'Feed Content',
            },
          ]
        : []),
      ...(isDesktop && user.roles && user.roles.includes('AvenuePortalEditor')
        ? [
            {
              id: `${elementId}-manage-insights-database-tab`,
              label: translations.profileManageInsightsDatabase,
              content: <ManageInsightsDatabase />,
              path: AvenueRoute.ProfileManageInsightsDatabase,
              gaName: 'Insights Database',
            },
          ]
        : []),
      ...(isDesktop &&
      user.roles &&
      (user.roles.includes('AvenueAdmin') ||
        user.roles.includes('AvenueSuperuser'))
        ? [
            {
              id: `${elementId}-manage-banners`,
              label: translations.profileManageBanners,
              content: <ManageBanners />,
              path: AvenueRoute.ProfileManageBanners,
              gaName: 'Banners',
            },
          ]
        : []),
    ];
    return _tabItems;
  }, [isDesktop, preferencesFormData, user.roles]);

  const activeTabIndex = useMemo(() => {
    const index = tabItems.findIndex(
      (tabItem) => tabItem.path === location.pathname
    );
    return index === -1 ? 0 : index;
  }, [location.pathname, tabItems]);

  useEffect(() => {
    fetchPreferencesFormData();
  }, [fetchPreferencesFormData]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (user.firstLoad && !user.isLoading) {
    return (
      <Page title={[translations.profile, tabItems[activeTabIndex].label]}>
        <AlertBar
          open
          type="critical"
          text={translations.profileFetchError}
          sx={(theme) => ({ margin: `${theme.spacing('md')} auto` })}
        />
      </Page>
    );
  }

  return (
    <Page
      title={[translations.profile, tabItems[activeTabIndex].label]}
      gridContainer
    >
      <Grid xs={12} lg={3}>
        <ProfileCard />
      </Grid>
      <Grid xs={12} lg={9}>
        <Stack
          sx={(theme) => ({
            backgroundColor: theme.colors.surface.informative,
            borderTopLeftRadius: theme.border.radius.md,
            borderTopRightRadius: theme.border.radius.md,
          })}
        >
          <ProfileInformation />
          <ProfileTabs
            items={tabItems}
            value={activeTabIndex}
            onChange={(
              _e: React.SyntheticEvent<Element, Event>,
              tabIndex: number
            ) => {
              const tab = tabItems[tabIndex];
              GAonClickProfileTab(tab.gaName);
              history.replace(tab.path);
            }}
          />
        </Stack>
        {tabItems.map((item, index) => (
          <Box
            key={item.id}
            role="tabpanel"
            id={`${item.id}-content`}
            aria-labelledby={item.id}
            hidden={index !== activeTabIndex}
            minHeight="50vh"
          >
            {index === activeTabIndex && item.content}
          </Box>
        ))}
        <AlertBar
          snackbar
          type="critical"
          open={fetchPreferencesFormDataStatus === RequestStatus.Failure}
          text={translations.profileFetchFormDataError}
          onClose={() => setFetchPreferencesFormDataStatus(RequestStatus.Idle)}
        />
      </Grid>
    </Page>
  );
};

export { Profile };
