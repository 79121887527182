import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Consumer } from 'components/CreateOrEditArticle';
import { DateTimePicker } from 'componentsNew';
import { translations } from 'translations';

type PublishDateFifteenMinutesStepProps = {
  sx?: SxProps<Theme>;
  disabled?: boolean;
};

const PublishDateFifteenMinutesStep = ({
  sx,
  disabled,
}: PublishDateFifteenMinutesStepProps) => {
  return (
    <Consumer>
      {({ onChangeArticle, updatedArticle }) => {
        const articlePublishedDateAsDate = Boolean(updatedArticle.publishedDate)
          ? new Date(updatedArticle.publishedDate)
          : null;

        return (
          <Stack
            sx={[
              (theme) => ({
                maxWidth: '17.5rem',
                gap: theme.spacing('xxs'),
              }),
              ...(Array.isArray(sx) ? sx : [sx]),
            ]}
          >
            <Typography variant="body1" fontWeight="bold">
              {translations.formLabelPublishDate}
            </Typography>
            <DateTimePicker
              id="publish-date-picker"
              timeSteps={{ minutes: 15 }}
              minutesStep={15}
              value={articlePublishedDateAsDate}
              disabled={disabled}
              onChange={(value) => {
                onChangeArticle(
                  value
                    ? {
                        publishedDate: value.toISOString(),
                      }
                    : {
                        publishedDate: null,
                      }
                );
              }}
            />
          </Stack>
        );
      }}
    </Consumer>
  );
};

export { PublishDateFifteenMinutesStep };
