import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'componentsNew';
import { translations } from 'translations';

const elementId = 'ai-chat-disclaimer';

const AIChatDisclaimer = () => {
  return (
    <Stack id={elementId} flexDirection="row">
      <Icon type="informationCircleFilled" color="secondary" size={20} />
      <Typography
        variant="caption"
        sx={(theme) => ({
          color: theme.colors.text.secondary,
          marginLeft: theme.spacing('xxs'),
          display: 'flex',
          alignItems: 'center',
        })}
      >
        {translations.aiChatDisclaimer}
      </Typography>
    </Stack>
  );
};

export { AIChatDisclaimer };
