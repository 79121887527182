import './EmbedPlayerenceButton.scss';

import PlayerenceModal from 'components/Editable/Files/PlayerenceModal';
import { Icon } from 'componentsNew';

type EmbedPlayerenceButtonProps = {
  onEmbedURL: (url: string) => void;
  title: string;
  close: () => void;
};

const EmbedPlayerenceButton = ({
  onEmbedURL,
  title,
  close,
}: EmbedPlayerenceButtonProps) => {
  return (
    <PlayerenceModal
      onSubmit={(data) => {
        onEmbedURL(data);
        close();
      }}
    >
      <div className="embed-playerence-button__container">
        <button>
          <Icon
            type="clipboardDocumentCheck"
            color="secondary"
            svgProps={{ width: 42, height: 42 }}
          />
        </button>
        <p className="embed-playerence-button__label">{title}</p>
      </div>
    </PlayerenceModal>
  );
};

export default EmbedPlayerenceButton;
