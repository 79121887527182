import ButtonModalWrapper from 'components/Button/ButtonModalWrapper';
import { cloneElement, useCallback, useState } from 'react';
import URL from 'url';

type PlayerenceModalProps = {
  onSubmit: (url: string) => void;
  children: React.ReactElement;
};

const PlayerenceModal = ({ onSubmit, children }: PlayerenceModalProps) => {
  const [input, setInput] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleSubmit = useCallback(() => {
    if (!input) {
      setError('Please enter a URL.');
      return;
    }
    const url = URL.parse(input, true);

    const isValid =
      url.hostname === 'app.playerence.com' && url.pathname?.includes('/quiz');

    if (!isValid) {
      setError(
        'Not a valid embed link from Playerence. Please check link and try again. It should be only the URL, no iframe tags.'
      );
      return;
    }

    onSubmit(url.href);
  }, [input, onSubmit]);

  const childElement = cloneElement(children, {
    onClick: handleSubmit,
    'aria-controls': 'playerence-modal',
  });

  return (
    <ButtonModalWrapper
      id="playerence-modal"
      title="Enter an embed link from Playerence"
      yesButton={{
        color: 'black',
        title: 'Submit',
        awaitTitle: 'Submit',
      }}
      noButton={{
        color: 'white',
        title: 'Close',
      }}
      errorMessage={error}
      modalChildren={
        <input
          className="modal__input"
          onChange={(e) => setInput(e.target.value)}
        />
      }
      awaitYes={true}
      onNoButtonClick={() => {
        setInput('');
        setError('');
      }}
    >
      {childElement}
    </ButtonModalWrapper>
  );
};

export default PlayerenceModal;
