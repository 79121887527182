import { Components, Palette, Theme } from '@mui/material/styles';
// eslint-disable-next-line no-restricted-imports
import { Typography } from '@mui/material/styles/createTypography';
// eslint-disable-next-line no-restricted-imports
import { Spacing } from '@mui/system/createTheme/createSpacing';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import { Border, Elevation, SemanticColors } from './theme';

const createMuiComponents = (
  muiPalette: Palette,
  muiColors: SemanticColors,
  muiSpacing: Spacing,
  muiBorder: Border,
  muiElevation: Elevation,
  muiTypography: Typography
) => {
  const components: Components<Omit<Theme, 'components'>> = {
    MuiCssBaseline: {
      styleOverrides: {},
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          display1: 'p',
          display2: 'p',
          display3: 'p',
          display4: 'p',
          srOnly: 'span',
        },
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'srOnly' && {
            position: 'absolute',
            border: 0,
            clip: 'rect(0, 0, 0, 0)',
            clipPath: 'inset(50%)',
            whiteSpace: 'nowrap',
          }),
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: muiColors.text.secondary,
          textDecorationColor: muiColors.text.secondary,
          '&:hover': {
            color: muiColors.text.actionHover,
          },
          '&.Mui-focusVisible': {
            outline: `1px solid ${muiColors.border.focus}`,
            boxShadow: `0px 0px 0px 3px ${muiPalette.secondary[300]}`,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        focusRipple: false,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: 'unset',
          },
          '&.Mui-focusVisible': {
            outline: `1px solid ${muiColors.border.focus}`,
            boxShadow: `0px 0px 0px 3px ${muiPalette.secondary[300]}`,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          transition: 'all 0.2s ease',
          boxShadow: 'none',
          '&.Mui-focusVisible': {
            outline: `1px solid ${muiColors.border.focus}`,
            boxShadow: `0px 0px 0px 3px ${muiPalette.secondary[300]}`,
          },
          '&.Mui-disabled': {
            color: muiColors.text.disabled,
            backgroundColor: muiColors.surface.disabled,
            border: 'none',
            svg: { color: muiColors.icon.secondary },
          },
          // Styles for button sizes "small", "medium", "large"
          '&.MuiButton-sizeSmall': {
            ...muiTypography.button3,
            padding: '0.5rem 1rem',
            '> .MuiButton-startIcon, > .MuiButton-endIcon': {
              svg: {
                width: 16,
                height: 16,
              },
            },
          },
          '&.MuiButton-sizeMedium': {
            ...muiTypography.button2,
            padding: '0.5rem 1.5rem',
            '> .MuiButton-startIcon, > .MuiButton-endIcon': {
              svg: {
                width: 24,
                height: 24,
              },
            },
          },
          '&.MuiButton-sizeLarge': {
            ...muiTypography.button1,
            padding: '1rem 2rem',
            '> .MuiButton-startIcon, > .MuiButton-endIcon': {
              svg: {
                width: 24,
                height: 24,
              },
            },
          },
          // Type "Primary" in ASSA ABLOY Global Design System
          // Variant "contained" in MUI Button
          '&.MuiButton-contained:not(.Mui-disabled)': {
            color: muiColors.text.inversePrimary,
            backgroundColor: muiColors.surface.actionPrimaryDefault,
            '> .MuiCircularProgress-root': {
              color: muiColors.icon.inversePrimary,
            },
            '&:hover': {
              backgroundColor: muiColors.surface.actionPrimaryHover,
              boxShadow: 'none',
            },
          },
          // Type "Secondary" in ASSA ABLOY Global Design System
          // Variant "outlined" in MUI Button
          '&.MuiButton-outlined:not(.Mui-disabled)': {
            color: muiColors.text.actionDefault,
            border: `${muiBorder.thickness[1]} solid ${muiColors.border.actionSecondaryDefault}`,
            backgroundColor: muiColors.surface.actionSecondaryDefault,
            '> .MuiCircularProgress-root': {
              color: muiColors.icon.brandBase,
            },
            '&:hover': {
              color: muiColors.text.actionHover,
              border: `${muiBorder.thickness[1]} solid ${muiColors.border.actionSecondaryHover}`,
              backgroundColor: muiColors.surface.actionSecondaryHover,
            },
            ':active': {
              color: muiColors.text.actionPress,
              backgroundColor: muiColors.surface.actionSecondaryPress,
            },
            ':focus': {
              color: muiColors.text.actionHover,
              backgroundColor: muiColors.surface.actionSecondaryDefault,
            },
          },
          // Type "Tertiary" in ASSA ABLOY Global Design System
          // Variant "text" in MUI Button
          '&.MuiButton-text:not(.Mui-disabled)': {
            color: muiColors.text.actionDefault,
            border: `${muiBorder.thickness[1]} solid ${muiColors.border.actionTertiaryDefault}`,
            backgroundColor: muiColors.surface.actionTertiaryDefault,

            '> .MuiCircularProgress-root': {
              color: muiColors.icon.primary,
            },
            '&:hover': {
              color: muiColors.text.actionHover,
              border: `${muiBorder.thickness[1]} solid ${muiColors.border.actionTertiaryHover}`,
              backgroundColor: muiColors.surface.actionTertiaryHover,
            },
            ':active': {
              color: muiColors.text.actionPress,
              border: `${muiBorder.thickness[1]} solid ${muiColors.border.actionTertiaryHover}`,
              backgroundColor: muiColors.surface.actionTertiaryPress,
            },
            ':focus': {
              color: muiColors.text.actionHover,
              backgroundColor: muiColors.surface.actionTertiaryDefault,
            },
          },
          // Styles for button variant "linkButton"
          '&.MuiButton-linkButton': {
            lineHeight: 1.2,
            fontWeight: 'normal',
            color: muiColors.text.tertiary,
            textDecorationLine: 'underline',
            '&:hover': {
              color: muiColors.text.actionHover,
              backgroundColor: muiColors.surface.actionTertiaryHover,
              textDecorationLine: 'underline',
            },
            '&.MuiButton-sizeSmall': {
              padding: `${muiSpacing('xxxs')} ${muiSpacing('xxs')}`,
            },
            '&.MuiButton-sizeMedium': {
              padding: `${muiSpacing('xxs')} ${muiSpacing('sm')}`,
            },
            '&.MuiButton-sizeLarge': {
              padding: `${muiSpacing('xs')} ${muiSpacing('md')}`,
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: 0,
          color: muiColors.text.tertiary,
          backgroundColor: muiColors.surface.primary,
          fieldset: {
            border: `1px solid ${muiColors.border.input}`,
          },
          'textarea::placeholder, input::placeholder': {
            color: muiColors.text.disabled,
            opacity: 1,
          },
          '.MuiSelect-icon': {
            color: muiColors.icon.secondary,
          },
          '&.Mui-focused': {
            boxShadow: `0 0 0 ${muiBorder.thickness[2]} ${muiPalette.secondary[300]}`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: muiColors.border.input,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: muiColors.border.input,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: muiBorder.thickness[1],
            borderColor: muiColors.border.focus,
          },
          '&.Mui-disabled': {
            color: muiColors.text.disabled,
            backgroundColor: muiColors.surface.disabled,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderWidth: muiBorder.thickness[1],
            borderColor: muiColors.border.disabled,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderWidth: muiBorder.thickness[1],
            borderColor: muiColors.border.critical,
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {},
        outlined: {
          '.MuiPaginationItem-root': {
            fontSize: muiTypography.caption.fontSize,
            color: muiColors.text.tertiary,
            '&.Mui-focusVisible, &.Mui-focusVisible.Mui-selected': {
              outline: `1px solid ${muiColors.border.focus}`,
              boxShadow: `0px 0px 0px 3px ${muiPalette.secondary[300]}`,
              backgroundColor: 'unset',
            },
          },
          '.MuiPaginationItem-previousNext': {
            borderRadius: muiBorder.radius.sm,
            borderColor: muiColors.border.surfacePrimary,
            '.MuiSvgIcon-root': {
              color: muiColors.icon.secondary,
            },
            '&:hover': {
              border: 'none',
              backgroundColor: muiColors.surface.actionPrimaryHover,
              '.MuiSvgIcon-root': {
                color: muiColors.icon.inversePrimary,
              },
            },
            '&.Mui-disabled': {
              opacity: 'unset',
              backgroundColor: muiColors.surface.disabled,
              borderColor: muiColors.border.disabled,
              svg: { color: muiColors.icon.secondary },
            },
          },
          '.MuiPaginationItem-page': {
            border: 'none',
            color: muiColors.text.primary,
            '&.Mui-selected': {
              backgroundColor: 'unset',
              fontWeight: 'bold',
            },
            '&:hover, &.Mui-selected:hover': {
              color: muiColors.text.inversePrimary,
              backgroundColor: muiColors.surface.actionPrimaryHover,
            },
            '&.Mui-disabled, &.Mui-disabled.Mui-selected': {
              background: 'unset',
            },
          },
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        label: null,
        reduceAnimations: true,
      },
    },
    MuiDateTimePicker: {
      defaultProps: {
        label: null,
        reduceAnimations: true,
        ampm: false,
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        root: {
          '.MuiDayCalendar-weekDayLabel': {
            color: muiColors.text.tertiary,
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          '.MuiPickersCalendarHeader-switchViewButton': {
            transition: 'none',
            '> .MuiSvgIcon-root': {
              color: muiColors.icon.secondary,
            },
          },
          '.MuiPickersCalendarHeader-label': {
            fontWeight: 'bold',
            color: muiColors.text.primary,
          },
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          '.MuiPickersArrowSwitcher-button': {
            border: `1px solid ${muiColors.border.surfacePrimary}`,
            borderRadius: muiBorder.radius.sm,
            '> .MuiSvgIcon-root': {
              color: muiColors.icon.secondary,
            },
          },
          '.MuiPickersArrowSwitcher-button:hover': {
            color: muiColors.text.inversePrimary,
            backgroundColor: muiColors.surface.actionPrimaryHover,
            '> .MuiSvgIcon-root': {
              color: muiColors.icon.inversePrimary,
            },
          },
          '.MuiPickersArrowSwitcher-button.Mui-disabled': {
            backgroundColor: muiColors.surface.disabled,
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          transition: 'none',
          color: muiColors.text.secondary,
          '&:hover': {
            fontWeight: 'bold',
            color: muiColors.text.inversePrimary,
            backgroundColor: muiColors.surface.actionPrimaryHover,
          },
          '&.MuiPickersDay-today': {
            fontWeight: 'bold',
            color: muiColors.text.brand,
            backgroundColor: muiColors.surface.informative,
            border: `1px solid ${muiColors.border.surfaceInformative}`,
            borderRadius: muiBorder.radius.sm,
          },
          '&.MuiPickersDay-today.Mui-selected': {
            borderRadius: '50%',
          },
          '&.Mui-selected': {
            fontWeight: 'bold',
            color: muiColors.text.inversePrimary,
            backgroundColor: muiColors.surface.actionPrimaryDefault,
          },
          '&.Mui-disabled:not(.Mui-selected)': {
            color: muiColors.text.disabled,
          },
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          '.MuiTimeClock-root': {
            height: '334px',
            '.MuiClock-root': { marginTop: 'auto' },
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          '.MuiPaper-root': {
            boxShadow: muiElevation.sm,
          },
        },
      },
    },
    MuiMultiSectionDigitalClockSection: {
      styleOverrides: {
        root: {
          '.MuiMenuItem-root': {
            '&:hover': {
              fontWeight: 'bold',
              color: muiColors.text.inversePrimary,
              backgroundColor: muiColors.surface.actionPrimaryHover,
            },
            '&.Mui-selected': {
              fontWeight: 'bold',
              color: muiColors.text.inversePrimary,
              backgroundColor: muiColors.surface.actionPrimaryDefault,
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        allowScrollButtonsMobile: true,
        selectionFollowsFocus: true,
      },
      styleOverrides: {
        root: {
          '.MuiButtonBase-root': {
            height: '3.4375rem',
            fontSize: muiTypography.body1.fontSize,
          },
          '.MuiTabs-indicator': {
            height: '0.1875rem',
            backgroundColor: muiColors.border.focus,
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: muiColors.text.disabled,
          transition: 'all 0.2s ease',
          fontWeight: 600,
          '&.Mui-selected': {
            color: muiColors.text.brand,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.MuiListItem-divider': {
            borderColor: muiColors.border.surfacePrimary,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 56,
          height: 32,
          padding: 0,
          overflow: 'visible',
          '&:focus-within': {
            '> .MuiSwitch-track': {
              outline: `1px solid ${muiColors.border.focus}`,
              boxShadow: `0px 0px 0px 3px ${muiPalette.secondary[300]}`,
            },
          },
        },
        switchBase: {
          padding: 0,
          margin: 4,
          transitionDuration: '200ms',
          '&.Mui-checked': {
            transform: `translateX(${32 - 2 * 2 * 2}px)`,
            color: muiColors.text.inversePrimary,
          },
          '&.Mui-checked > .MuiSwitch-thumb': {
            '::after': {
              content: '""',
              position: 'absolute',
              transform: 'rotate(50deg) translate(-50%, -50%)',
              top: 'calc(50%)',
              left: 'calc(50% - 5px)',
              height: '10px',
              width: '7px',
              borderBottom: `3px solid ${muiColors.icon.brandBase}`,
              borderRight: `3px solid ${muiColors.icon.brandBase}`,
            },
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: muiColors.icon.brandBase,
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: muiColors.surface.disabled,
            opacity: 0.5,
          },
          '&.Mui-disabled > .MuiSwitch-thumb': {
            backgroundColor: muiColors.surface.primary,
            '::after': {
              borderColor: muiColors.surface.disabled,
            },
          },
        },
        thumb: {
          width: 32 - 4 * 2,
          height: 32 - 4 * 2,
          boxSizing: 'border-box',
          boxShadow: muiElevation.xs,
        },
        track: {
          borderRadius: 32 / 2,
          backgroundColor: muiColors.surface.disabled,
          opacity: 1,
        },
      },
    },
    // Use wrapper component (componentsNew/Checkbox) to get correct icon
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          padding: 0,
          borderRadius: muiBorder.radius.sm,
          margin: '0.25rem',
          color: muiColors.text.tertiary,
          '&.Mui-checked': {
            color: muiColors.icon.brandBase,
          },
          '&.MuiCheckbox-indeterminate': {
            color: muiColors.icon.brandBase,
          },
          '&.Mui-disabled': {
            color: muiColors.text.disabled,
            '> svg > path': { fill: muiColors.surface.disabled },
          },
          ':hover': {
            backgroundColor: 'unset',
            '> svg > path': {
              fill: muiColors.text.actionHover,
            },
          },
          '&.Mui-focusVisible': {
            outline: `1px solid ${muiColors.border.focus}`,
            boxShadow: `0px 0px 0px 3px ${muiPalette.secondary[300]}`,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: muiColors.border.surfacePrimary,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
          '> .MuiFormControlLabel-label': {
            color: muiColors.text.secondary,
          },
          ':has(.Mui-disabled)': {
            '> .MuiFormControlLabel-label': {
              color: muiColors.text.disabled,
            },
          },
          '&.MuiFormControlLabel-labelPlacementStart': {
            '> .MuiFormControlLabel-label': {
              margin: '0 0.25rem 0 0',
            },
            ':has(.MuiSwitch-root)': {
              '> .MuiFormControlLabel-label': {
                margin: '0 0.5rem 0 0',
              },
            },
          },
          '&.MuiFormControlLabel-labelPlacementEnd': {
            '> .MuiFormControlLabel-label': {
              margin: '0 0 0 0.25rem',
            },
            ':has(.MuiSwitch-root)': {
              '> .MuiFormControlLabel-label': {
                margin: '0 0 0 0.5rem',
              },
            },
          },
          // Labels for Radio
          ':has(.MuiRadio-colorPrimary)': {
            '> .MuiRadio-colorPrimary.Mui-checked + .MuiFormControlLabel-label':
              {
                color: muiColors.text.actionDefault,
              },
            '> .MuiRadio-colorPrimary.Mui-disabled + .MuiFormControlLabel-label':
              {
                color: muiColors.text.disabled,
              },
            ':hover:not(.Mui-disabled)': {
              '> .MuiFormControlLabel-label': {
                color: muiColors.text.actionHover,
              },
              '> .MuiRadio-colorPrimary > svg > path': {
                fill: muiColors.text.actionHover,
              },
              '> .MuiRadio-colorPrimary.Mui-checked + .MuiFormControlLabel-label':
                {
                  color: muiColors.text.actionHover,
                },
            },
          },
          // Labels for Checkbox
          ':has(.MuiCheckbox-colorPrimary)': {
            '> .MuiCheckbox-colorPrimary.Mui-checked + .MuiFormControlLabel-label':
              {
                color: muiColors.text.actionDefault,
              },
            '> .MuiCheckbox-colorPrimary.Mui-disabled + .MuiFormControlLabel-label':
              {
                color: muiColors.text.disabled,
              },
            ':hover:not(.Mui-disabled)': {
              '> .MuiFormControlLabel-label': {
                color: muiColors.text.actionHover,
              },
              '> .MuiCheckbox-colorPrimary > svg > path': {
                fill: muiColors.text.actionHover,
              },
              '> .MuiCheckbox-colorPrimary.Mui-checked + .MuiFormControlLabel-label':
                {
                  color: muiColors.text.actionHover,
                },
            },
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          gap: '0.25rem',
        },
      },
    },
    // Use wrapper component (componentsNew/Radio) to get correct icon
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          padding: 0,
          margin: '0.25rem',
          color: muiColors.text.tertiary,
          '&:hover': {
            backgroundColor: 'unset',
            '> svg > path': {
              fill: muiColors.text.actionHover,
            },
          },
          '&.Mui-checked': {
            color: muiColors.icon.brandBase,
          },
          '&.Mui-disabled': {
            color: muiColors.text.disabled,
            '> svg > path': {
              fill: muiColors.surface.disabled,
            },
          },
          '&.Mui-focusVisible': {
            outline: `1px solid ${muiColors.border.focus}`,
            boxShadow: `0px 0px 0px 3px ${muiPalette.secondary[300]}`,
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: muiColors.surface.secondary,
          '&.MuiSkeleton-pulse': {
            backgroundColor: muiColors.surface.tertiary,
          },
          '&.MuiSkeleton-wave': {
            '&::after': {
              background:
                'linear-gradient(90deg, transparent, rgba(26, 26, 26, 0.03), transparent)',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: muiColors.text.primary,
          wordBreak: 'break-word',
          padding: `${muiSpacing('xxs')} ${muiSpacing('xs')}`,
          borderBottom: `1px solid ${muiColors.border.surfacePrimary}`,
          borderRight: `1px solid ${muiColors.border.surfacePrimary}`,
          '&:last-of-type': { borderRight: 'none' },
        },
        head: {
          color: muiColors.text.brand,
          fontWeight: 'bold',
          padding: `${muiSpacing('xxs')} ${muiSpacing('xs')}`,
          wordBreak: 'keep-all',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: muiColors.surface.secondary,
          borderBottom: `1px solid ${muiPalette.neutral[400]}`,
          borderTopLeftRadius: muiBorder.radius.md,
          borderTopRightRadius: muiBorder.radius.md,
        },
      },
    },
  };
  return components;
};

export { createMuiComponents };
